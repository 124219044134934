import React, { useState, useEffect } from "react";
import { Grid, Typography, CardContent, Card, Box, ThemeProvider } from "@mui/material";
import Header from "../Common/Headers/header";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserCurrentRole } from "../Common/Apis/api";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import logout from "../Common/Logout/logout";
import "./dealer.css";
import { LeftPanelComponent, DealerMenu, ComponentMapping, theme } from "../../constant2";

const Landing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  sessionStorage.setItem("customerType", "Dealer");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [selectedMenu, setSelectedMenu] = useState("Loan Details");

  const handleMenuSelect = (menu) => {
    setSelectedMenu(menu);
  };
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await getUserCurrentRole(handleLogoutButton);
        sessionStorage.setItem("scope", response.data.scope);
      } catch (error) { }
    };

    getUser();
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
      <Header />
      <Grid xs={12} md={12} lg={12} container spacing={2} style={{ height: "90vh", width: "99vw" }} sx={{ mt: "1px", ml: "1px" }}>
        <LeftPanelComponent menuItems={DealerMenu} componentMapping={ComponentMapping} />
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};
export default Landing;
