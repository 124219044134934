export const ActionTypes = {
  LOAN_REPORT_LOADING: "LOAN_REPORT_LOADING",
  LOAN_REPORT_SUCCESS: "LOAN_REPORT_SUCCESS",
  LOAN_REPORT_FAIL: "LOAN_REPORT_FAIL",
  LOAN_REPORT_CLEAR_DATA: "LOAN_REPORT_CLEAR_DATA",

  LOAN_DETAILS_LOADING: "LOAN_DETAILS_LOADING",
  LOAN_DETAILS_SUCCESS: "LOAN_DETAILS_SUCCESS",
  LOAN_DETAILS_FAIL: "LOAN_DETAILS_FAIL",
  LOAN_DETAILS_CLEAR_DATA: "LOAN_DETAILS_CLEAR_DATA",

  LOAN_SCHEDULE_LOADING: "LOAN_SCHEDULE_LOADING",
  LOAN_SCHEDULE_SUCCESS: "LOAN_SHCEDULE_SUCCESS",
  LOAN_SCHEDULE_FAIL: "LOAN_SCHEDULE_FAIL",
  LOAN_SCHEDULE_CLEAR_DATA: "LOAN_SCHEDULE_CLEAR_DATA",

  LOAN_DISBURSE_LOADING: "LOAN_DISBURSE_LOADING",
  LOAN_DISBURSE_SUCCESS: "LOAN_DISBURSE_SUCCESS",
  LOAN_DISBURSE_FAIL: "LOAN_DISBURSE_FAIL",
  LOAN_DISBURSE_CLEAR_DATA: "LOAN_DISBURSE_CLEAR_DATA",

  COLLECTIONS_REPORT_LOADING: "COLLECTIONS_REPORT_LOADING",
  COLLECTIONS_REPORT_SUCCESS: "COLLECTIONS_REPORT_SUCCESS",
  COLLECTIONS_REPORT_FAIL: "COLLECTIONS_REPORT_FAIL",
  COLLECTIONS_REPORT_CLEAR_DATA: "COLLECTIONS_REPORT_CLEAR_DATA",

  LOAN_CATEGORY_LOADING: "LOAN_CATEGORY_LOADING",
  LOAN_CATEGORY_SUCCESS: "LOAN_CATEGORY_SUCCESS",
  LOAN_CATEGORY_FAIL: "LOAN_CATEGORY_FAIL",
  LOAN_CATEGORY_CLEAR_DATA: "LOAN_CATEGORY_CLEAR_DATA",

  PORTFOLIO_DATA_LOADING: "PORTFOLIO_DATA_LOADING",
  PORTFOLIO_DATA_SUCCESS: "PORTFOLIO_DATA_SUCCESS",
  PORTFOLIO_DATA_FAIL: "PORTFOLIO_DATA_FAIL",
  PORTFOLIO_DATA_CLEAR_DATA: "PORTFOLIO_DATA_CLEAR_DATA",

  CUSTOMER_NAME_LOADING: "CUSTOMER_NAME_LOADING",
  CUSTOMER_NAME_SUCCESS: "CUSTOMER_NAME_SUCCESS",
  CUSTOMER_NAME_FAIL: "CUSTOMER_NAME_FAIL",
  CUSTOMER_NAME_CLEAR_DATA: "CUSTOMER_NAME_CLEAR_DATA",

  COLLECTIONS_STATUS_REPORT_LOADING: "COLLECTIONS_STATUS_REPORT_LOADING",
  COLLECTIONS_STATUS_REPORT_SUCCESS: "COLLECTIONS_STATUS_REPORT_SUCCESS",
  COLLECTIONS_STATUS_REPORT_FAIL: "COLLECTIONS_STATUS_REPORT_FAIL",
  COLLECTIONS_STATUS_REPORT_CLEAR_DATA: "COLLECTIONS_STATUS_REPORT_CLEAR_DATA",

  PARTNERS_LIST_LOADING: "PARTNERS_LIST_LOADING",
  PARTNERS_LIST_SUCCESS: "PARTNERS_LIST_SUCCESS",
  PARTNERS_LIST_FAIL: "PARTNERS_LIST_FAIL",
  PARTNERS_LIST_CLEAR_DATA: "PARTNERS_LIST_CLEAR_DATA",

  LOAN_DISBURSEMENT_LOADING: "LOAN_DISBURSEMENT_LOADING",
  LOAN_DISBURSEMENT_SUCCESS: "LOAN_DISBURSEMENT_SUCCESS",
  LOAN_DISBURSEMENT_FAIL: "LOAN_DISBURSEMENT_FAIL",
  LOAN_DISBURSEMENT_CLEAR_DATA: "LOAN_DISBURSEMENT_CLEAR_DATA",

  BANK_DETAILS_LOADING: "BANK_DETAILS_LOADING",
  BANK_DETAILS_SUCCESS: "BANK_DETAILS_SUCCESS",
  BANK_DETAILS_FAIL: "BANK_DETAILS_FAIL",
  BANK_DETAILS_CLEAR_DATA: "BANK_DETAILS_CLEAR_DATA",

  CLEAR_PERSISTED_STATE: "CLEAR_PERSISTED_STATE",

  REPORT_TYPE_LOADING: "REPORT_TYPE_LOADING",
  REPORT_TYPE_SUCCESS: "REPORT_TYPE_SUCCESS",
  REPORT_TYPE_FAIL: "REPORT_TYPE_FAIL",
  REPORT_TYPE_CLEAR_DATA: "REPORT_TYPE_CLEAR_DATA",

  DEALER_LOAN_DETAILS_LOADING: "DEALER_LOAN_DETAILS_LOADING",
  DEALER_LOAN_DETAILS_SUCCESS: "DEALER_LOAN_DETAILS_SUCCESS",
  DEALER_LOAN_DETAILS_FAIL: "DEALER_LOAN_DETAILS_FAIL",
  DEALER_LOAN_DETAILS_CLEAR_DATA: "DEALER_LOAN_DETAILS_CLEAR_DATA",

  TRANCHE_DETAILS_LOADING: "TRANCHE_DETAILS_LOADING",
  TRANCHE_DETAILS_SUCCESS: "TRANCHE_DETAILS_SUCCESS",
  TRANCHE_DETAILS_FAIL: "TRANCHE_DETAILS_FAIL",
  TRANCHE_DETAILS_CLEAR_DATA: "TRANCHE_DETAILS_CLEAR_DATA",
};
