import React from "react";
import "./Features.css";
import program_1 from "../../../Assets/integrations.png";
import program_2 from "../../../Assets/dataTransfer.jpg";
import program_3 from "../../../Assets/infrastructure.jpg";
import program_icon_1 from "../../../Assets/program-icon-1.png";
import program_icon_2 from "../../../Assets/program-icon-2.png";
import program_icon_3 from "../../../Assets/program-icon-3.png";

const Features = () => {
  return (
    <div className="programs">
      <div className="program">
        <img src={program_1} alt="" />
        <div className="caption">
          <img src={program_icon_1} alt="" />
          <p>Seamless Partner Integration</p>
        </div>
      </div>
      <div className="program">
        <img src={program_2} alt="" />
        <div className="caption">
          <img src={program_icon_2} alt="" />
          <p>Efficient Data Transfer</p>
        </div>
      </div>
      <div className="program">
        <img src={program_3} alt="" />
        <div className="caption">
          <img src={program_icon_3} alt="" />
          <p>Shared Account Infrastructure</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
