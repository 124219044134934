import React, { useState, useEffect } from "react";
import {Grid, Breadcrumbs, Button, LinearProgress, Box, Tooltip, Alert, ThemeProvider} from "@mui/material";
import Header from "../Common/Headers/header";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  getLoanReportsDetails,
} from "../Common/Apis/api";
import DisbursementDetailsPopup from "./confirmDisbursement";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import "./approvals.css";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";
import logout from "../Common/Logout/logout";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import {DataGrids, DateRenderCell, StatusRenderCell, blueButtonStyle, theme} from "../../constant2";
import CustomLoader from "../Common/CustomLoader/corestratLoader";

const DetailsTableView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [level, setLevel] = useState("");
  const loanReportsDetails = useSelector(
    (state) => state.DetailsReducers.loanDetailsResponse
  );
  const loanReportsDetailsLoading = useSelector(
    (state) => state.DetailsReducers.loanDetailsLoading
  );
  const loanDisburseResponse = useSelector(
    (state) => state.DetailsReducers.loanDisburseResponse
  );
  const loanDisburseLoading = useSelector(
    (state) => state.DetailsReducers.loanDisburseLoading
  );
  const [data, setdata] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    { field: "display_id", headerName: "ID", width: 120 },
    {
      field: "created_datetime",
      headerName: "Created Date",
      width: 120,
      renderCell: DateRenderCell,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 200,
      editable: false,
    },
    {
      field: "tenure",
      headerName: "Tenure",
      width: 80,
      editable: false,
    },
    {
      field: "loan_amount",
      headerName: "Loan Amount",
      width: 120,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
    {
      field: "approval_status",
      headerName: "Approval Status",
      width: 120,
      editable: false,
      align: "center",
      renderCell: StatusRenderCell,
    },
    {
      field: "esign_status",
      headerName: "Esign Status",
      width: 120,
      editable: false,
      align: "center",
      renderCell: StatusRenderCell,
    },
    {
      field: "disbursement_stage",
      headerName: "Disbursement Status",
      width: 200,
      editable: false,
      renderCell: (params) => {
        if (!params.row.esign_status) {
          return <div>-</div>; // Render a dash if Esign Status is false
        }

        let statusText = "";
        let icon = null;
        let color = "";

        switch (params.value) {
          case "PENDING":
            statusText = "Yet to be disbursed";
            color = "var(--primary-color)";
            break;
          case "PROCESSED":
            statusText = "Processed";
            color = "var(--primary-color)";
            break;
          case "SUCCESS":
            statusText = "Disbursement Details";
            color = "#1EED7D";
            break;
          case "FAIL":
            statusText = "FAIL";
            color = "red";
            break;
          default:
            break;
        }

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                color: color,
                marginRight: "5px",
              }}
            >
              <Typography style={{ fontSize: "0.9rem", fontWeight:500 }}>
                <span>{statusText}</span>
              </Typography>
            </div>
            {params.value === "PENDING" ? (
              <Tooltip title="Edit">
                <EditIcon
                  style={{
                    cursor: "pointer",
                    color: color,
                    fontSize: "20px",
                  }}
                  onClick={() => handleEditDisbursement(params.row)}
                />
              </Tooltip>
            ) : params.value === "SUCCESS" ? (
              <Tooltip title="Info">
                <InfoIcon
                  style={{
                    cursor: "pointer",
                    color: color,
                    fontSize: "20px",
                  }}
                  onClick={() => handleDisbursementClick(params.row)}
                />
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },

    {
      field: "schedule",
      headerName: "Schedule",
      align:"center",
      width: 150,
      renderCell: (params) => {
        return params.row.disbursement_status ? (
          <Button
            size="small"
            sx={blueButtonStyle}
            type="submit"
            onClick={() => handleScheduleClick(params.row)}
          >
            Schedule
          </Button>
        ) : (
          "-"
        );
      },
    },
  ];

  // Handle the button click event
  const handleScheduleClick = (row) => {
    // Add your logic for handling the schedule button click
    sessionStorage.setItem("LoanScheduleId", row.id);
    navigate("../../Schedule");
  };
  const handleDisbursementClick = (row) => {
    // Add your logic for handling the schedule button click
    sessionStorage.setItem("LoanScheduleId", row.id);
    navigate("../../DisbursementDetails");
  };
  useEffect(() => {
    const loanCategory = sessionStorage.getItem("LoanCategory");
    const key = sessionStorage.getItem("key");
    dispatch(getLoanReportsDetails(loanCategory,key, handleLogoutButton));
  }, []);
  useEffect(() => {
    const loanCategory = sessionStorage.getItem("LoanCategory");
     const key = sessionStorage.getItem("key");
    dispatch(getLoanReportsDetails(loanCategory,key, handleLogoutButton));
  }, [loanDisburseResponse]);

  const handleEditDisbursement = (row) => {
    // Add your logic for handling the edit icon click
    setSelectedRow(row);
    sessionStorage.setItem("FinappLoanCode", row.display_id);
    navigate("./../ConfirmDisbursement");
    //setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const accessLevel = levelsofAccess("Disbursement");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage( "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  useEffect(() => {
    if (loanReportsDetails != undefined && loanReportsDetails != null) {
      setdata(loanReportsDetails);
    }
  }, [loanReportsDetails]);
  const handleCloseErrorModal = () => {
    setModalOpen(false);
  };
  return (
    <div>
    <ThemeProvider theme={theme}>
      <Header />
      {loanReportsDetailsLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      {loanDisburseLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      {modalOpen ? (
        <div style={{ height: "50%", width: "80%" }}>
          <DisbursementDetailsPopup
            isOpen={modalOpen}
            onClose={handleCloseErrorModal}
            data={selectedRow}
          />
        </div>
      ) : null}
      {/* Breadcrumbs */}
      <Breadcrumbs style={{ position: "relative" }}>
        <Breadcrumb
          icon={<AssignmentIndIcon />}
          name="Dashboard"
          path="../../Dashboard"
        />
        <Breadcrumb
          icon={<CardTravelIcon />}
          name="Approvals and Disbursements"
          path="/Approvals"
        />
        <Breadcrumb
          icon={<FileCopyIcon />}
          name="Details"
          path="/DetailView"
          isCurrentPage
        />
      </Breadcrumbs>
      <Grid container spacing={2}>
        {showData ? (<>
        {data.length > 0 ? (
          <Grid item xs={12} sx={{ height: "77vh", width: "95vw", m: 4 }}>
            {/* Place the DataGrid within its own Grid item */}
           <DataGrids rows={data} columns={columns} page_size={10}/>
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ m: 4}}>
            <Alert severity="warning">
              No data available for this Category
            </Alert>
          </Grid>
        )}
        </>
      ):(<><Grid item xs={12} md={12}>
              <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
            </Grid></>)}
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default DetailsTableView;
