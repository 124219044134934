import React from "react";
import "./About.css";
import about_img from "../../../Assets/about.png";
import play_icon from "../../../Assets/play-icon.png";

const About = ({ setPlayState }) => {
  return (
    <div className="about">
      <div className="about-left">
        <img src={about_img} alt="" className="about-img" />
        <img
          src={play_icon}
          alt=""
          className="play-icon"
          onClick={() => {
            setPlayState(true);
          }}
        />
      </div>
      <div className="about-right">
        <h3>ABOUT LENDSPHERE</h3>
        <h2>Collaborative Lending Redefined</h2>
        <p>
          Lendsphere is an innovative co-lending platform that helps banks,
          NBFCs and other eligible lending agencies to collaborate & lend money
          to borrowers.
        </p>
        <p>
          As a middleware, Lendsphere provides a common platform for partner
          lending companies with different lending management systems to
          collaborate. This facilitates the efficient sharing of data, finances,
          and other resources, optimising lending capacity and diversifying risk
          exposure.
        </p>
      </div>
    </div>
  );
};

export default About;
