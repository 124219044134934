import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { getReports, getClientReport } from "../Common/Apis/api";
import "dayjs/locale/en"; // Import locale if needed
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import {ThemeProvider, LinearProgress, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import logout from "../Common/Logout/logout";
import Alert from "@mui/material/Alert";
import FullScreenDialog from "./FullScreenDialog";
import IconButton from "@mui/material/IconButton";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Tooltip from "@mui/material/Tooltip";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import { theme } from "../../constant2";

const BIReport = () => {
  const dispatch = useDispatch();
  const [downloadReportLoading, setdownloadReportLoading] =
    React.useState(false);
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [powerBiReport, setpowerBiReport] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isFullScreenOpen, setFullScreenOpen] = useState(false);
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const reportTypeLoading = useSelector(
    (state) => state.DetailsReducers.reportTypeLoading
  );

  useEffect(() => {
    const accessLevel = levelsofAccess("Collection");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage("You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleExpandClick = () => {
    setFullScreenOpen(true);
  };
  useEffect(async () => {
    try {
      const partnerId = sessionStorage.getItem("partnerId");
      const response = await getClientReport(partnerId, handleLogoutButton);
      if (response) {
        // console.log("Response is", response);
        const reports = response?.data?.powerbi_report?.replace(/\\/g, "");
        setpowerBiReport(reports);
      }
    } catch (error) {
      // console.log("error", error.message);
    }
  });
  return (
    <div>
      <ThemeProvider theme={theme}>
      {reportTypeLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          {/* <CustomLoader/> */}
        </Box>
      ) : null}
      {downloadReportLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          {/* <CustomLoader/> */}
        </Box>
      ) : null}
      {/* Breadcrumbs */}
      <div style={{ height: "78vh", overflow: "auto" }}>
      {showData ?(<>
        <Grid
          container                       //to remove bottomm scroll
          spacing={2}
          sx={{ mt: 2 }}  //to remove x axis scroll
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <Grid item xs={10}>
            <Typography variant="h6" gutterBottom>
              BI Reports
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Expand" arrow>
              <IconButton
                onClick={handleExpandClick}
                sx={{
                  color: "var(--primary-color)",
                }}
                disabled={powerBiReport == ""}
              >
                <OpenInFullIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <div
          style={{
            width: "90%",
            textAlign: "center",
          }}
        >
          {powerBiReport != "" ? (
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {/* <iframe
            title="KPM_PowerBI"
            width="1500"
            height="650"
            src="https://app.powerbi.com/reportEmbed?reportId=bf4c05ec-e584-4f66-9f2b-7b3f9136fb5e&autoAuth=true&ctid=e68e9cd5-b11f-4bb3-a0e0-3b8166d1843a"
            frameborder="0"
            allowFullScreen="true"
          ></iframe> */}
              <div
                dangerouslySetInnerHTML={{ __html: powerBiReport }}
                style={{ overflow: "auto", margin: "0% !important" }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={12} sx={{ mt: 2, ml: 5 }}>
              <Alert severity="error">Error Loading the Power BI Reports</Alert>
            </Grid>
          )}
        </div></>):(<><Grid item xs={12} md={12}>
            <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
          </Grid></>)}
      </div>
      <FullScreenDialog
        open={isFullScreenOpen}
        onClose={() => setFullScreenOpen(false)}
        powerBiReport={powerBiReport}
      />
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default BIReport;
