import React, { useState, useEffect } from "react";
import {Grid, CardContent, Card, TextField, Button, Alert, LinearProgress, Box, ThemeProvider} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import { Typography } from "@material-ui/core";
import logout from "../Common/Logout/logout";
import { getUserCurrentRole, tranchRequest } from "../Common/Apis/api";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import { useSelector } from "react-redux";
import "./dealer.css";
import {NumericFormatCustom, blueButtonStyle, greyButtonStyle, theme} from "../../constant2";

const TranchReq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState("");
  const [amount, setamount] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [alertType, setalertType] = React.useState("succcess");
  const [trancheRequestGenerated, settrancheRequestGenerated] =
    React.useState("");
  const dealerLoanDetails = useSelector(
    (state) => state.DetailsReducers.dealerLoanDetailResponse
  );
  const today = new Date();
  const formattedDate = `${(today.getDate()).toString().padStart(2, '0')}/${
    (today.getMonth() + 1).toString().padStart(2, '0')
  }/${today.getFullYear()}`;
  const handleAmountChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setamount(event.target.value);
  };
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await getUserCurrentRole(handleLogoutButton);
        sessionStorage.setItem("scope", response.data.scope);
      } catch (error) {}
    };

    getUser();
  }, []);
  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  const confirmDisbursement = async () => {
    settrancheRequestGenerated("");
    // console.log(NumericFormatCustom);
    // console.log(amount);
    try {
      setisAPILoading(true);
      const response = await tranchRequest(
        dealerLoanDetails?.loan_id,
        amount,
        handleLogoutButton
      );
      if (response) {
        setisAPILoading(false);
        settrancheRequestGenerated("Tranche Request Successfully Generated!");
        setalertType("success");
      }
    } catch (error) {
      setisAPILoading(false);
      // console.log("error", error.message);
      settrancheRequestGenerated(error.message);
      setalertType("error");
    }
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
      {isAPILoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : null}
      {trancheRequestGenerated != "" ? (
        <Alert severity={alertType}>{trancheRequestGenerated}</Alert>
      ) : null}
      <div style={{ height: "78vh", overflow: "auto" }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ width: "100%", Height: "40vh" }}
      >
        {dealerLoanDetails ?(
          <>
        <Grid
          container
          spacing={2}
          sx={{ m: 2, pt: 2 }}
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              color:"var(--primary-color)"
            }}
          >
            Tranche Request
          </Typography>
        </Grid>
        <Grid container spacing={2} sx={{ ml: 4, mr: 4, mt: 2, width: "60%" }}>
          <Card variant="outlined" style={{ height: "100%", width: "100%" }}>
            <CardContent>
              <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
                xs={12}
                md={12}
                lg={12}
                sx={{
                  pt: 3,
                  pb: 3,
                  pl: 3,
                  "@media (max-width: 1440px)": {
                    paddingLeft: 3,
                  },
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{ pl: 4, flex: 1 }}
                    component="div"
                    style={{ fontSize: "1rem" }}
                  >
                    <Typography
                      sx={{
                        minWidth: "200px",
                        fontWeight: "700",
                        paddingRight: "10px",
                      }}
                      component="div"
                      style={{
                        minWidth: "200px",
                        fontSize: "1rem",
                        fontWeight: "700",
                        display: "inline-block",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginTop: "15px",
                      }}
                    >
                      Amount:
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Enter amount"
                      className="amount-textfeild"
                      value={amount}
                      onChange={handleAmountChange}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ pl: 4, flex: 1 }}
                    component="div"
                    style={{ fontSize: "1rem" }}
                  >
                    <Typography
                      sx={{
                        minWidth: "200px",
                        fontWeight: "700",
                        paddingRight: "10px",
                      }}
                      component="div"
                      style={{
                        minWidth: "200px",
                        fontSize: "1rem",
                        fontWeight: "700",
                        display: "inline-block",
                      }}
                    >
                      Date of Tranche Request:
                    </Typography>
                    {formattedDate}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    sx={{ pl: 3, pr: 3, ...(amount === null || amount === "" ? {...greyButtonStyle} : {...blueButtonStyle}) }}
                    disabled={amount == null || amount == ""}
                    // style={{
                    //   whiteSpace: "nowrap",
                    //   marginLeft: "auto",
                    // }}
                    onClick={() => confirmDisbursement()}
                  >
                    Confirm Disbursement
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        </>
        ):(
          <>
          <Grid
            xs={12}
            sx={{ m: 2, pt: 2 }}
          ><Alert severity="warning">
              There is no user details available for the user.{" "}
            </Alert>
          </Grid>
          </>
        )}
      </Grid>
      </div>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default TranchReq;
