import { base_url } from "../../../constant";
import Axios from "axios";
import { ActionTypes } from "../../../Redux/Types/ActionTypes";

export const userLogin = async (credentials) => {
  const body = new URLSearchParams({
    username: credentials.username,
    password: credentials.password,
  });

  try {
    const response = await Axios.post(
      `${base_url}/auth/token`,
      body.toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (response.status === 200 && response.data) {
      return { status: response.status, data: response.data };
    } else {
      return { status: response.status, data: [] };
    }
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: [] };
    } else {
      throw new Error(error);
    }
  }
};

export const sendEmailToUser = async (email) => {
  try {
    const response = await Axios.post(`${base_url}/auth/forgot-password`, {
      email: email,
    });
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data.detail,
      };
    } else {
      throw new Error(error);
    }
  }
};

export const authorize = async (auth) => {
  try {
    const response = await Axios.get(
      `${base_url}/auth/confirm-user?token=${auth}`
    );
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data.detail,
      };
    } else {
      throw new Error(error);
    }
  }
};
export const usersignup = async (credentials) => {
  try {
    const response = await Axios.post(`${base_url}/auth/create-user`, {
      email: credentials.email,
      password: credentials.password,
      username: credentials.username,
      partner_id: parseInt(credentials.partner_id),
    });
    if (response.status === 200) {
      return { status: response.status, data: [] };
    } else {
      return { status: response.status, data: response.data };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data.detail,
      };
    } else {
      throw new Error(error);
    }
  }
};
export const ActivateUser = async (token) => {
  try {
    const response = await Axios.get(
      `${base_url}/auth/activate-user?act_token=${token}`
    );
    if (response.status === 200) {
      return { status: response.status, data: response.data.detail };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data.detail,
      };
    } else {
      throw new Error(error);
    }
  }
};
export const DeclineUser = async (token) => {
  try {
    const response = await Axios.get(
      `${base_url}/auth/decline-user?decline_token=${token}`
    );
    if (response.status === 200) {
      return { status: response.status, data: response.data.detail };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data.detail,
      };
    } else {
      throw new Error(error);
    }
  }
};
export const resetPasswordApiCall = async (email, password) => {
  try {
    const response = await Axios.post(
      `${base_url}/auth/save-new-password?email=${email}&password=${password}`
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const uploadData = async (data, navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url}/kpm/sendLoanApplicationsBulk`,
      data,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    throw new Error(error);
  }
};
// Get Loan Details -----------------//
export const getLoanReports = (id, navigateCallback, key) => (dispatch) => {
  dispatch(ReportsLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  if (key != "main") {
    Axios.get(
      `${base_url}/api/loans-report?loan_category_id=${id}&partner_name=${key}`,
      config
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.LOAN_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
        dispatch({
          type: ActionTypes.LOAN_REPORT_FAIL,
        });
      });
  } else {
    Axios.get(`${base_url}/api/loans-report?loan_category_id=${id}`, config)
      .then((response) => {
        dispatch({
          type: ActionTypes.LOAN_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
        dispatch({
          type: ActionTypes.LOAN_REPORT_FAIL,
        });
      });
  }
};
// Get partners  ---------------------//
export const getPartners = () => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  Axios.get(`${base_url}/api/partners`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.PARTNERS_LIST_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.PARTNERS_LIST_FAIL,
      });
    });
};
// Get Loan Details  -----------------//
export const getLoanReportsDetails =
  (categoryId, key, navigateCallback) => (dispatch) => {
    dispatch(LoanDetailsLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };
    if (key == "main") {
      Axios.get(`${base_url}/api/loans?loan_category_id=${categoryId}`, config)
        .then((response) => {
          dispatch({
            type: ActionTypes.LOAN_DETAILS_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.LOAN_DETAILS_FAIL,
          });
          if (error.response && error.response.status === 401) {
            navigateCallback();
          }
        });
    } else {
      Axios.get(
        `${base_url}/api/loans?loan_category_id=${categoryId}&partner_name=${key}`,
        config
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.LOAN_DETAILS_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.LOAN_DETAILS_FAIL,
          });
          if (error.response && error.response.status === 401) {
            navigateCallback();
          }
        });
    }
  };
// Get Loan Schedule -----------------//
export const getLoanSchedule = (loanId, navigateCallback) => (dispatch) => {
  dispatch(LoanScheduleLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  Axios.get(`${base_url}/api/schedules/${loanId}`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAN_SCHEDULE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.LOAN_SCHEDULE_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
      
    });
};

// export const disburseLoan =
//   (loanId, modeOfTransfer, navigateCallback) => (dispatch) => {
//     dispatch(LoanDisburseLoading());
//     const config = {
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
//       },
//     };

//     Axios.patch(
//       `${base_url}/kpm/confirm-disbursement/${loanId}?mode_of_disbursement=${modeOfTransfer}&schedule_type="80:20"`,
//       config
//     ) // <-- Corrected line
//       .then((response) => {
//         dispatch({
//           type: ActionTypes.LOAN_DISBURSE_SUCCESS,
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: ActionTypes.LOAN_DISBURSE_FAIL,
//         });
//         if (error.response && error.response.status === 401) {
//           navigateCallback();
//         }
//       });
//   };
                                                                                         
export const disburseLoan =                                                                                                      //Changed api 
  (loan_id, mode_of_transfer, disbursement_datetime, partner_name, navigateCallback) => (dispatch) => {                             
    dispatch(LoanDisburseLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };     
    const body = {                                                                                                                                     
      loan_id: loan_id,
      mode_of_transfer: mode_of_transfer,
      disbursement_datetime: disbursement_datetime,
      partner_name: partner_name,
    };
    Axios.post(
      `${base_url}/api/initiate-disbursement`,
       body,config
    ) 
      .then((response) => {
        dispatch({
          type: ActionTypes.LOAN_DISBURSE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.LOAN_DISBURSE_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };


export const getUserCurrentRole = async (navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.get(`${base_url}/auth/current`, config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
  }
};
// Get Loan Details  -----------------//
export const getCollectionsReport =
  (loanCode, key, navigateCallback) => (dispatch) => {
    dispatch(CollectionsReportLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };
    if (key == "main") {
      Axios.get(`${base_url}/api/collection/${loanCode}`, config)
        .then((response) => {
          dispatch({
            type: ActionTypes.COLLECTIONS_REPORT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.COLLECTIONS_REPORT_FAIL,
          });
          if (error.response && error.response.status === 401) {
            navigateCallback();
          }
        });
    } else {
      Axios.get(
        `${base_url}/api/collection/${loanCode}?partner_name=${key}`,
        config
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.COLLECTIONS_REPORT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.COLLECTIONS_REPORT_FAIL,
          });
          if (error.response && error.response.status === 401) {
            navigateCallback();
          }
        });
    }
  };
export const uploadCollectionReports = async (data, navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url}/kpm/update-collection`,
      data,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    throw new Error(error);
  }
};
// Get Loan Details -----------------//
export const getLoanCategories = (navigateCallback) => (dispatch) => {
  dispatch(LoanCategoriesLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  Axios.get(`${base_url}/api/loan-categories`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAN_CATEGORY_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
      dispatch({
        type: ActionTypes.LOAN_CATEGORY_FAIL,
      });
    });
};
// Get Loan Schedule -----------------//
export const getPotfolioData =
  (loanId, key, navigateCallback) => (dispatch) => {
    dispatch(PortfolioReportLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };
    if (key == "main") {
      Axios.get(
        `${base_url}/api/collection-report?loan_category_id=${loanId}`,
        config
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.PORTFOLIO_DATA_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.PORTFOLIO_DATA_FAIL,
          });
          if (error.response && error.response.status === 401) {
            navigateCallback();
          }
        });
    } else {
      Axios.get(
        `${base_url}/api/collection-report?loan_category_id=${loanId}&partner_name=${key}`,
        config
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.PORTFOLIO_DATA_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.PORTFOLIO_DATA_FAIL,
          });
          if (error.response && error.response.status === 401) {
            navigateCallback();
          }
        });
    }
  };
// Get Customer Names on Category -----------------//
export const getCustomerNames = (category, navigateCallback) => (dispatch) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  Axios.get(`${base_url}/api/customers?loan_category_id=${category}`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.CUSTOMER_NAME_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.CUSTOMER_NAME_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};
// Get Loan Category Status -----------------//
export const getLoanCategoryStatus =
  (category, key, navigateCallback) => (dispatch) => {
    dispatch(CollectionsStatusReportLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };
    if (key == "main") {
      Axios.get(`${base_url}/api/loans?loan_category_id=${category}`, config)
        .then((response) => {
          dispatch({
            type: ActionTypes.COLLECTIONS_STATUS_REPORT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.COLLECTIONS_STATUS_REPORT_FAIL,
          });
          if (error.response && error.response.status === 401) {
            navigateCallback();
          }
        });
    } else {
      Axios.get(
        `${base_url}/api/loans?loan_category_id=${category}&partner_name=${key}`,
        config
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.COLLECTIONS_STATUS_REPORT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.COLLECTIONS_STATUS_REPORT_FAIL,
          });
          if (error.response && error.response.status === 401) {
            navigateCallback();
          }
        });
    }
  };
// Get Loan Disbursement -----------------//
export const getLoanDisbursement = (loanId, navigateCallback) => (dispatch) => {
  dispatch(LoanDisbursementLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  Axios.get(`${base_url}/api/disbursement-details?loan_id=${loanId}`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.LOAN_DISBURSEMENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.LOAN_DISBURSEMENT_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};

//Get Bank Details-----------------------//
export const BankDetailsLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.BANK_DETAILS_LOADING,
  });
};

export const getBankDetails = (loanId, key, navigateCallback) => (dispatch) => {
  dispatch(BankDetailsLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  if (key == "main") {
    Axios.get(`${base_url}/api/${loanId}/customer/bank-details`, config)
      .then((response) => {
        dispatch({
          type: ActionTypes.BANK_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.BANK_DETAILS_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  } else {
    Axios.get(
      `${base_url}/api/${loanId}/customer/bank-details?partner_name=${key}`,
      config
    )
      .then((response) => {
        dispatch({
          type: ActionTypes.BANK_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.BANK_DETAILS_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  }
};
//--------------------- verify Account ---------------------//
export const verifyAccountPennyDrop = async (loanId, navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url}/fed/penny-drop`,
      {
        loan_id: loanId,
      },
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    throw new Error(error);
  }
};
//--------------------- Check Balance ---------------------//
export const checkBalance = async (navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.get(`${base_url}/fed/balance-check`, config);
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    throw new Error(error);
  }
};
//------------------ Get reports----------------------------------//
export const getReports = (navigateCallback) => (dispatch) => {
  dispatch(getReportsLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  Axios.get(`${base_url}/api/report-types`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.REPORT_TYPE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.REPORT_TYPE_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};
//------------ Download Report------------------------------------------//
export const downloadReports = async (type, date1, date2, navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  let url;
  if (date2 && date1==null) {
    url = `${base_url}/api/report?report_type=${type}&till_date=${date2}`;
  } else {
    url = `${base_url}/api/report?report_type=${type}&till_date=${date2}&from_date=${date1}`;
  }
  try {
    const response = await Axios.get(url,
      config
    );
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    throw new Error(error);
  }
};
//----------------------- clear Persisted Storage ----------------------------------
export const clearPersistedStore = (navigateCallback) => (dispatch) => {
  dispatch(clearPersistedState());
};

//------------------------ generate OTP ------------------------------------------
export const generateotp = async (mobile_number, navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url}/auth/generate-otp`,
      {
        mobile_number: mobile_number,
      },
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    throw new Error(error);
  }
};
//------------------------verify otp--------------------------------------
export const verifyotp = async (mobile_number, otp, navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url}/auth/verify-otp`,
      {
        mobile_number: mobile_number,
        otp: otp,
      },
      config
    );
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    throw new Error(error);
  }
};
//---------------- Get partner-------------------------------
export const getPartner = async (userName) => {
  try {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };
    const response = await Axios.get(
      `${base_url}/api/partner?username=${userName}`,
      config
    );
    if (response.status === 200) {
      return { status: response.status, data: response };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data.detail,
      };
    } else {
      throw new Error(error);
    }
  }
};

export const userLoginDealer = async () => {
  const body = new URLSearchParams({
    username: sessionStorage.getItem("username"),
    password: " ",
    scope: "dealer",
  });

  try {
    const response = await Axios.post(
      `${base_url}/auth/token`,
      body.toString(),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    if (response.status === 200 && response.data) {
      return { status: response.status, data: response.data };
    } else {
      return { status: response.status, data: [] };
    }
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: [] };
    } else {
      throw new Error(error);
    }
  }
};
//---------------------------Get Dealer Loan Details-------------------
export const getDealerLoanDetail = () => (dispatch) => {
  dispatch({
    type: ActionTypes.BANK_DETAILS_LOADING,
  });
};

export const getDealerLoanDetails =
  (mobile_number, navigateCallback) => (dispatch) => {
    dispatch(DealerLoanDetailLoading());
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };
    Axios.get(`${base_url}/petromoney/dealer-loan/${mobile_number}`, config)
      .then((response) => {
        dispatch({
          type: ActionTypes.DEALER_LOAN_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.DEALER_LOAN_DETAILS_FAIL,
        });
        if (error.response && error.response.status === 401) {
          navigateCallback();
        }
      });
  };
export const getTranchesDetails = (loan_id, navigateCallback) => (dispatch) => {
  dispatch(TrancheDetailLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  Axios.get(`${base_url}/api/schedules/${loan_id}`, config)
    .then((response) => {
      dispatch({
        type: ActionTypes.TRANCHE_DETAILS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.TRANCHE_DETAILS_FAIL,
      });
      if (error.response && error.response.status === 401) {
        navigateCallback();
      }
    });
};
export const tranchRequest = async (loan_id, amount, navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url}/petromoney/tranche-request`,
      {
        loan_id: loan_id,
        amount: parseInt(amount),
        tenure: null,
        disbursement_date: null,
      },
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    let errorMessage = "Error during tranche request";
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    if (error.response && error.response.data && error.response.data.detail) {
      errorMessage += `: ${error.response.data.detail}`;
    }
    throw new Error(errorMessage);
  }
};
export const getReloadTranchesDetails = async (loan_id, navigateCallback) => {
  //dispatch(TrancheDetailLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.get(
      `${base_url}/petromoney/reload-limit/${loan_id}`,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
  }
};
export const reloadRequest = async (loan_id, amount, navigateCallback) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.post(
      `${base_url}/petromoney/reload-loan`,
      {
        loan_id: loan_id,
        amount: parseInt(amount),
      },
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
    throw new Error(error);
  }
};
export const getClientReport = async (partnerId, navigateCallback) => {
  //dispatch(TrancheDetailLoading());
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
  try {
    const response = await Axios.get(
      `${base_url}/api/client-settings?partner_id=${partnerId}`,
      config
    );
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      navigateCallback();
    }
  }
};
//----------------------- Loaders-----------------------------------
export const ReportsLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_REPORT_LOADING,
  });
};

export const LoanCategoriesLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_CATEGORY_LOADING,
  });
};

export const PortfolioReportLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.PORTFOLIO_DATA_LOADING,
  });
};

export const CollectionsReportLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.COLLECTIONS_REPORT_LOADING,
  });
};

export const CollectionsStatusReportLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.COLLECTIONS_STATUS_REPORT_LOADING,
  });
};

export const LoanDisburseLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_DISBURSE_LOADING,
  });
};

export const LoanScheduleLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_SCHEDULE_LOADING,
  });
};

export const LoanDetailsLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_DETAILS_LOADING,
  });
};
export const LoanDisbursementLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOAN_DISBURSEMENT_LOADING,
  });
};
export const clearPersistedState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_PERSISTED_STATE,
  });
};
export const getReportsLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.REPORT_TYPE_LOADING,
  });
};
export const DealerLoanDetailLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.DEALER_LOAN_DETAILS_LOADING,
  });
};
export const TrancheDetailLoading = () => (dispatch) => {
  dispatch({
    type: ActionTypes.TRANCHE_DETAILS_LOADING,
  });
};
