import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from "redux-persist";
import rootReducer from './Reducers/Index';

const persistConfig = {
  key: "root",             //key is root
  storage: storageSession,       //storage type is local
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
 
export default persistedReducer;