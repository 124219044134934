import React from "react";
import "./Benefits.css";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MemoryIcon from "@mui/icons-material/Memory";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TuneIcon from "@mui/icons-material/Tune";
const Benefits = ({ title, description, icon }) => {
  return (
    <div className="benefit-container">
      <div className="hover-effect">
        <i className="material-icons">{icon}</i>
        <h3>{title}</h3>
        <div className="hover-text">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const BenefitsList = () => {
  const benefitsData = [
    {
      title: "Digital Process",
      description:
        "With the lending process being fully digital, there's no paperwork involved, and all the events are tracked for all parties involved.",
      icon: <MemoryIcon style={{ fontSize: "3rem" }} />, // Assuming 'description' is the Material-UI icon name
    },
    {
      title: "Fractional Loan Scheduling",
      description:
        "Repayments can be scheduled fractionally for all lenders involved, corresponding to the portion of the loan each lender provided.",
      icon: <WatchLaterIcon style={{ fontSize: "3rem" }} />, // Assuming 'schedule' is the Material-UI icon name
    },
    {
      title: "Automated Approval & Disbursal",
      description:
        "With the integrated BRE, Lendsphere approves and disburses the loan automatically in a matter of minutes.",
      icon: <CheckCircleIcon style={{ fontSize: "3rem" }} />, // Assuming 'done' is the Material-UI icon name
    },
    {
      title: "Customisable",
      description:
        "Lendsphere can be customised according to the needs of the lenders and can be integrated easily within their existing system through flexible APIs.",
      icon: <TuneIcon style={{ fontSize: "3rem" }} />, // Assuming 'build' is the Material-UI icon name
    },
  ];

  return (
    <div className="campus">
      <div className="gallery">
        {benefitsData.map((benefit, index) => (
          <div
            key={index}
            className="hover-container"
            style={{
              backgroundColor: "#0093fb",
              color: "white",
              padding: "20px",
              borderRadius: "10px",
              height: "40vh",
              marginLeft: "2%",
              marginTop:"1%",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Benefits
              title={benefit.title}
              description={benefit.description}
              icon={benefit.icon}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitsList;
