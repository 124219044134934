import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../Reducers/Index";
import persistedReducer from "../persistConfig";
import { persistStore } from "redux-persist";

const persistedStateJSON = sessionStorage.getItem('reduxPersistedState');
const initialState = persistedStateJSON ? JSON.parse(persistedStateJSON) : {};
const middleware = [thunk];
const store = createStore(
  persistedReducer,
  initialState,
  compose(applyMiddleware(...middleware))
);

store.subscribe(() => {
  const state = store.getState();
  sessionStorage.setItem('reduxPersistedState', JSON.stringify(state));
});
const persistor=persistStore(store);
export {store,persistor};
