import React, { useEffect } from "react";
import { useNavigate} from "react-router-dom";
import "./header.css"; // You can create a separate CSS file for styling
import corelogo from "./../../../Assets/corelogo.png";
import { useDispatch } from 'react-redux';
import {clearPersistedStore} from "../Apis/api";
import logout from "../Logout/logout";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

const navigateToDashboard = () => {
    navigate("/Dashboard");
  };

  return (
    <>
      <div className="header">
        <div className="logo">
          <img src={corelogo} alt="Colending Logo" />
          <span className="logoHeading">Lendsphere</span>
        </div>
        <div className="options">
        {sessionStorage.getItem("customerType") !== "Dealer" ?
          (<span className="menuItems" onClick={navigateToDashboard}>
            Dashboard
          </span>):(<></>)
          }
          <span className="menuItems" onClick={()=>logout(navigate, dispatch)}>
            Logout
          </span>
        </div>
      </div>
      <hr className="headerLine" />
    </>
  );
};
export default Header;
