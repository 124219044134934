import React, { useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Grid,
  Alert,
  FormHelperText
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "./login.css"; // Assume the styles are defined in a separate CSS file
import {
  userLogin,
  authorize,
  sendEmailToUser,
  resetPasswordApiCall,
  getPartner,
} from "./../Common/Apis/api";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import { blueButtonStyle, greyButtonStyle } from "../../constant2";
import { checkAccess } from "../Common/AccessLevels/accessLevel";
const Login = () => {
  var auth_arr = [" ", " "],
    auth;
  const navigate = useNavigate();
  const [isPageLoad, setisPageLoad] = React.useState(false);
  const [loginbutton, setLoginButton] = React.useState(false); // State to track the login button state
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [partnerlogin, setPartnerLogin] = React.useState(false);
  const [isForgotPassword, setisForgotPassword] = React.useState(false);
  const [isForgotDetails, setisForgotDetails] = React.useState(false);
  const [wrongPassword, setwrongPassword] = React.useState(false);
  const [PasswordError, setPasswordError] = React.useState(false);
  const [wrongAuthentication, setwrongAuthentication] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [confirmUser, setconfirmUser] = React.useState(false);
  const [resetPassword, setresetPassword] = React.useState(false);
  const [inactiveuser, setinactiveuser] = React.useState(false);
  const [ifnotuser, setifnotuser] = React.useState(false);
  const [email, setemail] = React.useState("");
  const [isValid, setIsValid] = useState(false);
  const [isWrongPassword, setisWrongPassword] = useState(false);
  const [emptyUsername, setEmptyUsername] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [resetPasswordFields, setresetPasswordFields] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const togglePasswordVisibility = () => {
    setViewPassword(!viewPassword);
  }

  const handleForgotPasswordClick = () => {
    setisForgotPassword(!isForgotPassword);
    setresetPassword(false);
    setPartnerLogin(false);
    setconfirmUser(false);
    setEmailError(false);
    setwrongPassword(false);
    setwrongAuthentication(false);
  };
  const handleloginotheraccountClick = () => {
    setEmailError(false);
    setwrongPassword(false);
    setwrongAuthentication(false);
    setLoginButton(false);
    sessionStorage.clear();
    setCredentials({
      username: "",
      password: "",
    });
  };
  const handleInputChangeCredentials = (e) => {
    setEmptyPassword(false);
    setEmptyUsername(false);
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  // const handleInputChangeAuth = (e) => {
  //   setauth({ ...auth, [e.target.name]: e.target.value });
  // };
  const handleInputChangePassword = (e) => {
    setPasswordError(false);
    setresetPasswordFields({
      ...resetPasswordFields,
      [e.target.name]: e.target.value,
    });
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (regex.test(e.target.value)) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };
  const handleInputConfirmPassword = (e) => {
    setIsValid(false);
    setresetPasswordFields({
      ...resetPasswordFields,
      [e.target.name]: e.target.value,
    });
    if (resetPasswordFields.password != e.target.value) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  const handleInputChange = (e) => {
    setinactiveuser(false);
    setifnotuser(false);
    setemail(e.target.value);
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (regex.test(e.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  const usernameloginbutton = async (event) => {
    sessionStorage.setItem("username", credentials.username);
    event.preventDefault();
    if (credentials.username != "") {
      try {
        setisAPILoading(true);
        const response = await getPartner(credentials.username);
        setisAPILoading(false);

        // console.log(loginbutton);
        if (response.status == 200) {
          sessionStorage.setItem("access_token", response.data.access_token);
          setisForgotDetails(true);
          if (response.data.data.name == "kpm") {
            sessionStorage.setItem("partnerId", response.data.data.id);
            setLoginButton(true);
            setPartnerLogin(false);
          } else {
            setPartnerLogin(true);
          }
        }
        if (response.status == 401) {
          setwrongPassword(true);
        }
        if (response.status == 403) {
          setinactiveuser(true);
        }
        if (response.status == 404) {
          setifnotuser(true);
        }
      } catch (error) {
        setisAPILoading(false);
      }
    } else {
      if (credentials.username == "") {
        setEmptyUsername(true);
      } else {
        setEmptyPassword(true);
      }
    }
  };
  useEffect(() => {
    const username = sessionStorage.getItem("username");
    if (username != "") {
      credentials.username = username;
    } else {
      setEmptyUsername(true);
    }
  }, []);
  const navigatetoDashboard = async (event) => {
    setEmptyUsername(false);
    setIsLoginClicked(true);
    setinactiveuser(false);
    setifnotuser(false);
    event.preventDefault(); // Prevent the default form submission
    if (credentials.username != "" && credentials.password != "") {
      try {
        setisAPILoading(true);
        const response = await userLogin(credentials);
        setisAPILoading(false);
        if (response.status == 200) {
          sessionStorage.setItem("access_token", response.data.access_token);
          sessionStorage.removeItem("username");
          navigate("/dashboard");
        }
        if (response.status == 401) {
          setwrongPassword(true);
        }
        if (response.status == 403) {
          setinactiveuser(true);
        }
        if (response.status == 404) {
          setifnotuser(true);
        }
      } catch (error) { }
    } else {
      if (credentials.username == "") {
        setEmptyUsername(true);
      } else {
        setEmptyPassword(true);
      }
    }
  };
  const confirmDetails = async (e) => {
    setwrongAuthentication(false);
    // e.preventDefault();
    try {
      setisAPILoading(true);
      const response = await authorize(auth);
      setisAPILoading(false);
      const data = response.data;
      const result = data.result;
      setemail(data.email);
      if (result == true) {
        setresetPassword(true);
      } else {
        setwrongAuthentication(true);
      }
    } catch (error) { }
  };
  const sendEmail = async (e) => {
    setinactiveuser(false);
    setifnotuser(false);
    e.preventDefault();
    if (email != "") {
      try {
        setisAPILoading(true);
        const response = await sendEmailToUser(email);
        setisAPILoading(false);
        if (response.status == 200) {
          setisForgotPassword(false);
        }
        if (response.status == 403) {
          setinactiveuser(true);
        }
        if (response.status == 404) {
          setifnotuser(true);
        }
      } catch (error) { }
    } else {
      setEmptyEmail(true);
    }
  };
  if (!isPageLoad) {
    const url = window.location.href;
    const authcheck = "?token=";
    if (url.includes(authcheck)) {
      setresetPassword(false);
      setconfirmUser(true);
      setwrongPassword(false);
      setwrongAuthentication(false);
      setisForgotPassword(true);
      setisForgotDetails(true);
      auth_arr = url.split("=");
      auth = auth_arr[1];
      confirmDetails();
    }
    setisPageLoad(true);
  }
  const resetPasswordFunction = async (e) => {
    e.preventDefault();
    setisAPILoading(true);
    const response = await resetPasswordApiCall(
      email,
      resetPasswordFields.password
    );
    setisAPILoading(false);
    setisForgotPassword(false);
  };
  const internalclick = () => {
    setLoginButton(true);
    setPartnerLogin(false);
    sessionStorage.setItem("customerType", "Internal");
  };

  const dealerclick = () => {
    navigate("/verification");
    sessionStorage.setItem("customerType", "Dealer");
  };
  return (
    <Grid container className="login-container">
      {isAPILoading ? <CustomLoader /> : null}
      <Grid item xs={12} md={6} lg={6} className="blue-background">
        <div className="co-lending-container">
          <Typography variant="h4" className="co-lending-text">
            Lendsphere
          </Typography>
          <Typography variant="body1" className="tagline-text">
            Your Trusted Financial Partner
          </Typography>
        </div>
      </Grid>
      {!partnerlogin ? (
        <Grid item xs={12} md={6} lg={6} className="login-form">
          <form className="form-grid">
            {!isForgotPassword ? (
              <>
                {sessionStorage.getItem("dealerType") !== "Internal" &&
                  !loginbutton ? (
                  <>
                    <Typography variant="h5" gutterBottom textAlign="center">
                      Login
                    </Typography>
                    {wrongPassword ? (
                      <>
                        {sessionStorage.getItem("customerType") !==
                          "Internal" && !loginbutton ? (
                          <Alert severity="error">Wrong Username</Alert>
                        ) : (
                          <Alert severity="error">Wrong Password.</Alert>
                        )}
                      </>
                    ) : null}
                    {emptyUsername || emptyPassword ? (
                      <>
                        {sessionStorage.getItem("customerType") !==
                          "Internal" && !loginbutton ? (
                          <Alert severity="error">
                            Please enter a Username
                          </Alert>
                        ) : (
                          <Alert severity="error">Please enter Password.</Alert>
                        )}
                      </>
                    ) : null}
                    {inactiveuser ? (
                      <Alert severity="error">
                        User Inactive. Please Contact Admin
                      </Alert>
                    ) : null}
                    {ifnotuser ? (
                      <Alert severity="error">User does not Exist.</Alert>
                    ) : null}

                    <TextField
                      label="Username"
                      id="username"
                      name="username"
                      variant="outlined"
                      className="input"
                      fullWidth
                      error={emptyUsername}
                      value={credentials.username}
                      onChange={(e) => handleInputChangeCredentials(e)}
                    />
                    <Grid container className="sign-up-spacing">
                      <Grid item xs={12} md={8} className="sign-up">
                        <Typography variant="body2">
                          <a
                            className="sign-up-link"
                            onClick={handleSignUpClick}
                          >
                            Not a Registered User? Sign Up
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      sx={blueButtonStyle}
                      onClick={usernameloginbutton}
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="h5" gutterBottom textAlign="center">
                      Password
                    </Typography>
                    {wrongPassword ? (
                      <>
                        {sessionStorage.getItem("customerType") !==
                          "Internal" && !loginbutton ? (
                          <Alert severity="error">Wrong Username</Alert>
                        ) : (
                          <Alert severity="error">Wrong Password.</Alert>
                        )}
                      </>
                    ) : null}
                    {emptyUsername || emptyPassword ? (
                      <>
                        {sessionStorage.getItem("customerType") !==
                          "Internal" && !loginbutton ? (
                          <Alert severity="error">
                            Please enter a Username
                          </Alert>
                        ) : (
                          <Alert severity="error">Please enter Password.</Alert>
                        )}
                      </>
                    ) : null}
                    {inactiveuser ? (
                      <Alert severity="error">
                        User Inactive. Please Contact Admin
                      </Alert>
                    ) : null}
                    {ifnotuser ? (
                      <Alert severity="error">User does not Exist.</Alert>
                    ) : null}

                    <TextField
                      label="Password"
                      id="password"
                      name="password"
                      type={viewPassword ? "text" : "password"}
                      variant="outlined"
                      className="input"
                      fullWidth
                      error={emptyPassword}
                      value={credentials.password}
                      onChange={(e) => handleInputChangeCredentials(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={togglePasswordVisibility} edge="end">
                              {viewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Grid container className="links-spacing">
                      <Grid item className="forgot-password">
                        <Typography
                          variant="body2"
                          style={{ textAlign: "left" }}
                        >
                          <a
                            className="forgot-password-link"
                            onClick={handleForgotPasswordClick}
                          >
                            Forgot Password?
                          </a>
                        </Typography>
                      </Grid>
                      <Grid item className="login-other-acc">
                        <Typography variant="body2">
                          <a
                            className="login-other-acc-link"
                            onClick={handleloginotheraccountClick}
                          >
                            Login other account
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      sx={blueButtonStyle}
                      onClick={navigatetoDashboard}
                    >
                      Login
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                <Typography variant="h5" gutterBottom textAlign="center">
                  Forgot Password
                </Typography>
                {wrongAuthentication ? (
                  <Alert severity="error">
                    Authentication token not correct
                  </Alert>
                ) : null}
                {emptyEmail ? (
                  <Alert severity="error">Please enter an email.</Alert>
                ) : null}
                {inactiveuser ? (
                  <Alert severity="error">
                    User Inactive. Please Contact Admin.
                  </Alert>
                ) : null}
                {ifnotuser ? (
                  <Alert severity="error">User does not Exist.</Alert>
                ) : null}
                {!confirmUser ? (
                  <>
                    <TextField
                      label="Email"
                      id="email"
                      variant="outlined"
                      className="input"
                      fullWidth
                      name="email"
                      value={email}
                      error={emailError}
                      onChange={(e) => handleInputChange(e)}
                      helperText={emailError ? "Email is Invalid." : null}
                    />
                    <div className="forgot-password">
                      <Typography variant="body2">
                        <a
                          className="forgot-password-link"
                          onClick={handleForgotPasswordClick}
                        >
                          Login
                        </a>
                      </Typography>
                    </div>

                    <Button
                      type="submit"
                      sx={blueButtonStyle}
                      onClick={(event) => sendEmail(event)}
                    >
                      Send Authentication Email
                    </Button>
                  </>
                ) : (
                  <>
                    {!resetPassword ? (
                      <></>
                    ) : (
                      <>
                        <TextField
                          label="New Password"
                          id="password"
                          variant="outlined"
                          className="input"
                          fullWidth
                          type="password"
                          value={resetPasswordFields.password}
                          name="password"
                          error={PasswordError}
                          onChange={(e) => handleInputChangePassword(e)}
                          helperText={
                              <FormHelperText style={{ color: "var(--fail-color)", fontSize: '0.6rem' }}>
                              { PasswordError ? "Your password must be have at least 8 characters long 1 uppercase & 1 lowercase character 1 number" : null}
                            </FormHelperText>
                          }
                        />
                        <TextField
                          label="Confirm New Password"
                          id="confirmPassword"
                          variant="outlined"
                          className="input"
                          fullWidth
                          type="password"
                          name="confirmPassword"
                          value={resetPasswordFields.confirmPassword}
                          error={isValid}
                          onChange={(e) => handleInputConfirmPassword(e)}
                          helperText={
                          <FormHelperText style={{ color: "var(--fail-color)", fontSize: '0.6rem' }}>
                              {isValid ? "Password Invalid / Doesn't match" : null}
                            </FormHelperText>
                          }
                        />
                        <div className="forgot-password">
                          <Typography variant="body2">
                            <a
                              className="forgot-password-link"
                              onClick={handleForgotPasswordClick}
                            >
                              Login
                            </a>
                          </Typography>
                        </div>
                        <Button
                          type="submit"
                          margin-top="10px"
                          disabled={isValid || PasswordError}
                          onClick={(event) => resetPasswordFunction(event)}
                          sx={{mt:"10px", ...(isValid || PasswordError || resetPasswordFields.password=="") ? 
                            {...greyButtonStyle}:{...blueButtonStyle}}}  //when password feild id empty
                        >
                          Reset
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </form>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={6} className="login-form">
            <form className="form-grid">
              <div>
                <Typography variant="h5" gutterBottom textAlign="center">
                  Login As
                </Typography>
              </div>
              {/*wrong phone number case*/}
              <FormControl fullWidth sx={{ m: 1 }}>
                <div className="login-button-container">
                  <Button
                    type="submit"
                    className="login-button"
                    onClick={internalclick}
                    sx={{ mr: "5em", ...blueButtonStyle }}
                    style={{
                      height: "50px",
                      width: "100px",
                    }}
                  >
                    Internal
                  </Button>
                  <Button
                    type="submit"
                    sx={blueButtonStyle}
                    onClick={dealerclick}
                    style={{
                      height: "50px",
                      width: "100px",
                    }}
                  >
                    Dealer
                  </Button>
                </div>
              </FormControl>
            </form>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default Login;
