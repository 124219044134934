import React, { useState, useEffect } from "react";
import {
    TextField,
    InputAdornment,
    IconButton,
    Typography,
    Button,
    Grid,
    Alert,
    FormHelperText,
    Link,
    Container,
    FormControl,
    MenuItem,
    ThemeProvider
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGooglePlusG,
    faFacebookF,
    faGithub,
    faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import "./login2.css";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import {
    userLogin,
    authorize,
    sendEmailToUser,
    resetPasswordApiCall,
    getPartner,
    generateotp,
    verifyotp,
    userLoginDealer,
    usersignup,
    ActivateUser,
    getPartners,
    DeclineUser,
} from "./../Common/Apis/api";
import logout from "../Common/Logout/logout";
import { blue } from "@mui/material/colors";
import { blueButtonStyle, greyButtonStyle, theme } from "../../constant2";
import { jwtDecode } from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import ReusableSnackbar from "./../Common/Snackbar/snackbar";

// import ProfilePage from "../Common/Profile/profilePage";
// import { merchantenquiry } from "../Common/Apis/api";
// import { MerchantEnquiryData } from "../../constant2";

function Login2() {
    const [isSignUp, setIsSignUp] = useState(false);
    var auth_arr = [" ", " "],
        auth;
    var act_url = [" ", " "],
        act_token;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isPageLoad, setisPageLoad] = React.useState(false);
    const [loginbutton, setLoginButton] = React.useState(false); // State to track the login button state
    const [isAPILoading, setisAPILoading] = React.useState(false);
    const [partnerlogin, setPartnerLogin] = React.useState(false);
    const [isForgotPassword, setisForgotPassword] = React.useState(false);
    const [isForgotDetails, setisForgotDetails] = React.useState(false);
    const [wrongPassword, setwrongPassword] = React.useState(false);
    const [PasswordError, setPasswordError] = React.useState(false);
    const [wrongAuthentication, setwrongAuthentication] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [confirmUser, setconfirmUser] = React.useState(false);
    const [resetPassword, setresetPassword] = React.useState(false);
    const [inactiveuser, setinactiveuser] = React.useState(false);
    const [ifnotuser, setifnotuser] = React.useState(false);
    const [emailSignUp, setEmailSignUp] = React.useState("");
    const [isValid, setIsValid] = useState(false);
    const [isWrongPassword, setisWrongPassword] = useState(false);
    const [emptyUsername, setEmptyUsername] = useState(false);
    const [emptyPassword, setEmptyPassword] = useState(false);
    const [isLoginClicked, setIsLoginClicked] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [emptySignUpEmail, setEmptySignUpEmail] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [verification, setVerification] = useState(false);
    const [email, setEmail] = React.useState("");
    const [credentials, setCredentials] = useState({
        username: "",
        password: "",
    });
    const [resetPasswordFields, setresetPasswordFields] = useState({
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error");
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
    const [otpClick, setOtpClick] = useState(false);
    const [otp, setOtp] = useState("");
    const [OTPerror, setOTPerror] = React.useState(false);
    const [invalidOtpCount, setInvalidOtpCount] = React.useState(0);
    const [otpErrorMessage, setOtpErrorMessage] = React.useState("");
    const partnersArray = useSelector(
        (state) => state.DetailsReducers.partnersListResponse
    );
    const [password, setPassword] = React.useState("");
    const [failtext, setfailtext] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [partner_id, setPartner_ID] = React.useState("");
    const [issignedup, setissignedup] = React.useState(false);
    const [isactivate, setisactivate] = React.useState(false);
    const [isdeclined, setisdeclined] = React.useState(false);
    const [issignupfail, setissignupfail] = React.useState(false);
    const [isactivatefail, setisactivatefail] = React.useState(false);
    const [isdeclinefail, setisdeclinefail] = React.useState(false);
    const [emptyPartner, setEmptyPartner] = useState(false);
    const [credentialsSignUp, setCredentialsSignUp] = useState({
        emailSignUp: "",
        username: "",
        password: "",
        partner_id: "",
    });
    useEffect(() => {
        dispatch(getPartners());
    }, []);
    const handleDropdownChange = (e) => {
        setEmptyPartner(false);
        setPartner_ID(e.target.value);
        setCredentialsSignUp({ ...credentialsSignUp, [e.target.name]: e.target.value });
    };

    const handleloginClick = () => {
        setIsSignUp(false);
        // navigate("/login");
    };

    const handleInputUsername = (e) => {
        setEmptyUsername(false);
        setUsername(e.target.value);
        setCredentialsSignUp({ ...credentialsSignUp, [e.target.name]: e.target.value });
    };

    const handleInputPassword = (e) => {
        setEmptyPassword(false);
        setPassword(e.target.value);
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        if (regex.test(e.target.value)) {
            setPasswordError(false);
            setCredentialsSignUp({ ...credentialsSignUp, [e.target.name]: e.target.value });
        } else {
            setPasswordError(true);
        }
    };

    const handleInputEmail = (e) => {
        setEmptyEmail(false);
        setEmptySignUpEmail(false);
        setEmailSignUp(e.target.value);
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (regex.test(e.target.value)) {
            console.log("error");
            setEmailError(false);
            setCredentialsSignUp({ ...credentialsSignUp, [e.target.name]: e.target.value });
        } else {
            setEmailError(true);
        }
    };

    const signupclick = async (event) => {
        setissignupfail(false);
        event.preventDefault(); // Prevent the default form submission
        if (
            credentialsSignUp.username != "" &&
            credentialsSignUp.partner_id != "" &&
            credentialsSignUp.email != "" &&
            credentialsSignUp.password != ""
        ) {
            try {
                setisAPILoading(true);
                const response = await usersignup(credentialsSignUp);
                setisAPILoading(false);
                if (response.status == 201) {
                    setissignedup(true);
                    setTimeout(() => {
                        navigate("/");
                    }, 2000);
                }
                if (response.status == 400) {
                    setfailtext(response.data);
                    setissignupfail(true);
                }
            } catch (error) {
            }
        } else {
            if (credentialsSignUp.username == "") {
                setEmptyUsername(true);
            } else {
                if (credentialsSignUp.email == "") {
                    setEmptyEmail(true);
                } else {
                    if (credentialsSignUp.password == "") {
                        setEmptyPassword(true);
                    } else {
                        setEmptyPartner(true);
                    }
                }
            }
        }
    };

    const act_User = async (e) => {
        try {
            setisAPILoading(true);
            const response = await ActivateUser(act_token);
            setisAPILoading(false);
            if (response.status == 200) {
                setisactivate(true);
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            }
            if (response.status == 404) {
                setfailtext(response.data);
                setisactivatefail(true);
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            }
        } catch (error) {
        }
    };

    const decline_User = async (e) => {
        try {
            setisAPILoading(true);
            const response = await DeclineUser(act_token);
            setisAPILoading(false);
            if (response.status == 200) {
                setisdeclined(true);
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            }
            if (response.status == 404) {
                setfailtext(response.data);
                setisdeclinefail(true);
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            }
        } catch (error) {
        }
    };

    if (!isPageLoad) {
        const url = window.location.href;
        const actcheck = "?act_token=";
        const declinecheck = "?decline_token=";
        if (url.includes(actcheck)) {
            act_url = url.split("?act_token=");
            act_token = act_url[1];
            act_User();
        }
        if (url.includes(declinecheck)) {
            act_url = url.split("?decline_token=");
            act_token = act_url[1];
            decline_User();
        }
        setisPageLoad(true);
    }

    const handleOTPChange = (val) => {
        setOtp(val);
    };

    const getotpclick = async (event) => {
        event.preventDefault();
        if (phoneNumber !== "" && !phoneNumberError) {
            // sessionStorage.setItem("mobile_number", phoneNumber);
            try {
                const result = await generateotp(phoneNumber);
                if (result) {
                    setOtpClick(true);
                }
            } catch (error) { }
        } else {
            setPhoneNumberError(true);
        }
    };

    const handleresendclick = async (event) => {
        event.preventDefault();
        setOtpErrorMessage("");
        if (phoneNumber !== "" && !phoneNumberError) {
            try {
                const result = await generateotp(phoneNumber);
                if (result?.data) {
                    setOtpClick(true);
                }
            } catch (error) { }
        }
    };

    const handleverifyclick = async () => {
        setOTPerror(false);
        setOtpErrorMessage("");
        try {
            const result = await verifyotp(phoneNumber, otp);
            if (result.status == 200) {
                setOtpClick(true);
                const response = await userLoginDealer();
                setisAPILoading(false);
                if (response.status == 200) {
                    sessionStorage.setItem("access_token", response.data.access_token);
                    navigate("/landing");
                }
            }
        } catch (error) {
            setOtpErrorMessage("Incorrect OTP");
        }
    };

    const handleInputPhoneNumber = (e) => {
        setEmptyPhoneNumber(false);
        setPhoneNumber(e.target.value);
        const regex = /^[0-9]{10}$/;
        if (regex.test(e.target.value)) {
            setPhoneNumberError(false);
        } else {
            setPhoneNumberError(true);
            setEmptyPhoneNumber(true);
        }
        if (e.target.value == "") {
            setEmptyPhoneNumber(true);
        }
    };

    const handleLogoutButton = () => {
        setSnackbarMessage("Session has expired. Please login again.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
        setTimeout(() => {
            logout(navigate, dispatch);
        }, 3000);
    };
    const closeSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleSignUp = () => {
        setIsSignUp(true);
    };

    const handleSignIn = () => {
        setIsSignUp(false);
    };
    const handleSignUpClick = () => {
        navigate("/signup");
    };

    const togglePasswordVisibility = () => {
        setViewPassword(!viewPassword);
    }

    const handleForgotPasswordClick = () => {
        setisForgotPassword(!isForgotPassword);
        setresetPassword(false);
        setPartnerLogin(false);
        setconfirmUser(false);
        setEmailError(false);
        setwrongPassword(false);
        setwrongAuthentication(false);
    };
    const handleloginotheraccountClick = () => {
        setVerification(false);
        setPartnerLogin(false);
        setEmailError(false);
        setwrongPassword(false);
        setwrongAuthentication(false);
        setLoginButton(false);
        sessionStorage.clear();
        setCredentials({
            username: "",
            password: "",
        });
    };
    const handleInputChangeCredentials = (e) => {
        setEmptyPassword(false);
        setEmptyUsername(false);
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };
    // const handleInputChangeAuth = (e) => {
    //   setauth({ ...auth, [e.target.name]: e.target.value });
    // };
    const handleInputChangePassword = (e) => {
        setPasswordError(false);
        setresetPasswordFields({
            ...resetPasswordFields,
            [e.target.name]: e.target.value,
        });
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        if (regex.test(e.target.value)) {
            setPasswordError(false);
            if (resetPasswordFields.confirmPassword != e.target.value) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        } else {
            setPasswordError(true);
        }
    };
    const handleInputConfirmPassword = (e) => {
        setIsValid(false);
        setresetPasswordFields({
            ...resetPasswordFields,
            [e.target.name]: e.target.value,
        });
        if (resetPasswordFields.password != e.target.value) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };
    const handleInputChange = (e) => {
        setinactiveuser(false);
        setifnotuser(false);
        setEmail(e.target.value);
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (regex.test(e.target.value)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };
    const usernameloginbutton = async (event) => {
        // sessionStorage.setItem("username", credentials.username);
        event.preventDefault();
        if (credentials.username != "") {
            try {
                setisAPILoading(true);
                const response = await getPartner(credentials.username);
                setisAPILoading(false);

                // console.log(loginbutton);
                if (response.status == 200) {
                    sessionStorage.setItem("access_token", response.data.access_token);
                    setisForgotDetails(true);
                    if (response.data.data.name == "kpm") {
                        sessionStorage.setItem("partnerId", response.data.data.id);
                        setLoginButton(true);
                        setPartnerLogin(false);
                    } else {
                        setPartnerLogin(true);
                    }
                }
                if (response.status == 401) {
                    setwrongPassword(true);
                }
                if (response.status == 403) {
                    setinactiveuser(true);
                }
                if (response.status == 404) {
                    setifnotuser(true);
                }
            } catch (error) {
                setisAPILoading(false);
            }
        } else {
            if (credentials.username == "") {
                setEmptyUsername(true);
            } else {
                setEmptyPassword(true);
            }
        }
    };
    // useEffect(() => {
    //     const username = sessionStorage.getItem("username");
    //     if (username != "") {
    //         credentials.username = username;
    //     } else {
    //         setEmptyUsername(true);
    //     }
    // }, []);

    useEffect(() => {
        console.log("otp: ", otp);
    }, [otp]);

    const navigatetoDashboard = async (event) => {
        setEmptyUsername(false);
        setIsLoginClicked(true);
        setinactiveuser(false);
        setifnotuser(false);
        event.preventDefault(); // Prevent the default form submission
        if (credentials.username != "" && credentials.password != "") {
            try {
                setisAPILoading(true);
                const response = await userLogin(credentials);
                setisAPILoading(false);
                if (response.status == 200) {
                    sessionStorage.setItem("access_token", response.data.access_token);
                    sessionStorage.removeItem("username");
                    navigate("/dashboard");
                }
                if (response.status == 401) {
                    setwrongPassword(true);
                }
                if (response.status == 403) {
                    setinactiveuser(true);
                }
                if (response.status == 404) {
                    setifnotuser(true);
                }
            } catch (error) { }
        } else {
            if (credentials.username == "") {
                setEmptyUsername(true);
            } else {
                setEmptyPassword(true);
            }
        }
    };
    const confirmDetails = async (e) => {
        setwrongAuthentication(false);
        // e.preventDefault();
        try {
            setisAPILoading(true);
            const response = await authorize(auth);
            setisAPILoading(false);
            const data = response.data;
            const result = data.result;
            setEmail(data.email);
            if (result == true) {
                setresetPassword(true);
            } else {
                setwrongAuthentication(true);
            }
        } catch (error) { }
    };
    const sendEmail = async (e) => {
        setinactiveuser(false);
        setifnotuser(false);
        e.preventDefault();
        if (email != "") {
            try {
                setisAPILoading(true);
                const response = await sendEmailToUser(email);
                setisAPILoading(false);
                if (response.status == 200) {
                    setisForgotPassword(false);
                }
                if (response.status == 403) {
                    setinactiveuser(true);
                }
                if (response.status == 404) {
                    setifnotuser(true);
                }
            } catch (error) { }
        } else {
            setEmptyEmail(true);
        }
    };
    if (!isPageLoad) {
        const url = window.location.href;
        const authcheck = "?token=";
        if (url.includes(authcheck)) {
            setresetPassword(false);
            setconfirmUser(true);
            setwrongPassword(false);
            setwrongAuthentication(false);
            setisForgotPassword(true);
            setisForgotDetails(true);
            auth_arr = url.split("=");
            auth = auth_arr[1];
            confirmDetails();
        }
        setisPageLoad(true);
    }
    const resetPasswordFunction = async (e) => {
        e.preventDefault();
        setisAPILoading(true);
        const response = await resetPasswordApiCall(
            email,
            resetPasswordFields.password
        );
        setisAPILoading(false);
        setisForgotPassword(false);
    };
    const internalclick = () => {
        setLoginButton(true);
        setPartnerLogin(false);
        sessionStorage.setItem("customerType", "Internal");
    };

    const dealerclick = () => {
        // navigate("/verification");
        setPartnerLogin(false);
        setVerification(true);
        sessionStorage.setItem("customerType", "Dealer");
    };

    // const selectDifferentUser = () =>{
    //     setPartnerLogin(true);
    // };

    return (
        <div className="bodyLogin">
            {/* {isAPILoading? (<CustomLoader/>):null} */}
            
    <ThemeProvider theme={theme}>
            <Container
                className={`container ${isSignUp ? "active" : ""}`}
                id="container"
            >
                <div className={`form-container ${isSignUp ? "sign-up" : "sign-in"}`}>
                    {isSignUp ? (
                        <form>
                            {/* <Typography style={{ fontSize: "1.5rem" }}>Sign Up</Typography> */}
                            {/* <Grid container className="signup-container"> */}
                            {isAPILoading ? <CustomLoader /> : null}
                            <Grid item xs={12} md={6}>
                                <form>
                                    <div>
                                        <Typography variant="h5" gutterBottom textAlign="center">
                                            Sign Up
                                        </Typography>
                                    </div>
                                    {isactivatefail ? (
                                        <Alert
                                            severity="error"
                                            style={{
                                                maxHeight: "5vh",
                                            }}
                                        >
                                            {failtext}
                                        </Alert>
                                    ) : null}
                                    {isdeclinefail ? (
                                        <Alert
                                            severity="error"
                                            style={{
                                                maxHeight: "5vh",
                                            }}
                                        >
                                            {failtext}
                                        </Alert>
                                    ) : null}
                                    {isactivate ? (
                                        <Alert
                                            severity="success"
                                            style={{
                                                maxHeight: "5vh",
                                            }}
                                        >
                                            User actiavted successfully
                                        </Alert>
                                    ) : null}
                                    {isdeclined ? (
                                        <Alert
                                            severity="success"
                                            style={{
                                                maxHeight: "5vh",
                                            }}
                                        >
                                            User declined successfully
                                        </Alert>
                                    ) : null}
                                    {issignedup ? (
                                        <Alert
                                            severity="success"
                                            style={{
                                                maxHeight: "5vh",
                                            }}
                                        >
                                            User signed up successfully
                                        </Alert>
                                    ) : null}
                                    {issignupfail ? <Alert severity="error">{failtext}</Alert> : null}
                                    {emptyUsername ? (
                                        <Alert severity="error">Please enter a Username.</Alert>
                                    ) : null}
                                    {emptyPartner ? (
                                        <Alert severity="error">Please select a Partner</Alert>
                                    ) : null}
                                    {emptyPassword ? (
                                        <Alert severity="error">Please enter your password</Alert>
                                    ) : null}
                                    {emptySignUpEmail ? (
                                        <Alert severity="error">Please enter an Email</Alert>
                                    ) : null}
                                    <TextField
                                        label="Email"
                                        id="email"
                                        name="email"
                                        variant="outlined"
                                        className="input"
                                        fullWidth
                                        value={emailSignUp}
                                        error={emailError}
                                        onChange={(e) => handleInputEmail(e)}
                                        helperText={emailError ? "Email is Invalid." : null}
                                        sx={{ mb: 1, mt: 2 }}
                                        InputLabelProps={{
                                            style: { fontSize: 13 },
                                        }}
                                    />
                                    <TextField
                                        label="Username"
                                        id="username"
                                        name="username"
                                        variant="outlined"
                                        className="input"
                                        fullWidth
                                        value={username}
                                        onChange={(e) => handleInputUsername(e)}
                                        sx={{ mb: 1 }}
                                        InputLabelProps={{
                                            style: { fontSize: 13 },
                                        }}
                                    />
                                    <TextField
                                        label="Password"
                                        id="password"
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        className="input"
                                        fullWidth
                                        value={password}
                                        error={PasswordError}
                                        onChange={(e) => handleInputPassword(e)}
                                        helperText={
                                            PasswordError
                                                ? "Your password must be have at least 8 characters long 1 uppercase & 1 lowercase character 1 number"
                                                : null
                                        }
                                        sx={{ mb: 1 }}
                                        InputLabelProps={{
                                            style: { fontSize: 13 },
                                        }}
                                    />
                                    <TextField
                                        select
                                        value={partner_id}
                                        onChange={(e) => handleDropdownChange(e)}
                                        fullWidth
                                        name="partner_id"
                                        label="Select Partner"
                                        sx={{ mb: 1 }}
                                        InputLabelProps={{
                                            style: { fontSize: 13 },
                                        }}
                                    >
                                        {partnersArray?.map((option, key) => (
                                            <MenuItem
                                                key={key}
                                                value={option.id}
                                                sx={{
                                                    fontFamily: "'Roboto Condensed', sans-serif",
                                                    "&:hover": {
                                                        borderLeft: "5px solid var(--primary-color)",
                                                        borderRadius: 1,
                                                    },
                                                }}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {/* <div className="login">
                                            <Typography variant="body2">
                                                <a className="login-link" onClick={handleloginClick}>
                                                    Existing User? Login.
                                                </a>
                                            </Typography>
                                        </div> */}

                                    <Button
                                        type="submit"
                                        className="signup-button"
                                        sx={blueButtonStyle}
                                        onClick={(event) => signupclick(event)}
                                    >
                                        Sign Up
                                    </Button>
                                </form>
                            </Grid>
                            {/* </Grid> */}
                        </form>
                    ) : (
                        <form>
                            <Typography style={{ fontSize: "1.5rem" }}>{isForgotPassword ? "Forgot Password" : "Sign In"}</Typography>
                            <Grid
                                container
                                justifyContent="center"
                                spacing={2}
                                className="social-icons"
                                sx={{ m: 1 }}
                            >
                                <Grid item>
                                    <Link href="#" className="icon disabled-icon">
                                        <FontAwesomeIcon icon={faGooglePlusG} />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" className="icon disabled-icon">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" className="icon disabled-icon">
                                        <FontAwesomeIcon icon={faGithub} />
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" className="icon disabled-icon">
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </Link>
                                </Grid>
                            </Grid>
                            {!verification ? (<>
                                {!partnerlogin ? (
                                    <Grid item xs={12} md={6} lg={6}>
                                        <form>
                                            {!isForgotPassword ? (
                                                <>
                                                    {sessionStorage.getItem("dealerType") !== "Internal" &&
                                                        !loginbutton ? (
                                                        <>
                                                            {wrongPassword ? (
                                                                <>
                                                                    {sessionStorage.getItem("customerType") !==
                                                                        "Internal" && !loginbutton ? (
                                                                        <Alert severity="error">Wrong Username</Alert>
                                                                    ) : (
                                                                        <Alert severity="error">Wrong Password.</Alert>
                                                                    )}
                                                                </>
                                                            ) : null}
                                                            {emptyUsername || emptyPassword ? (
                                                                <>
                                                                    {sessionStorage.getItem("customerType") !==
                                                                        "Internal" && !loginbutton ? (
                                                                        <Alert severity="error">
                                                                            Please enter a Username
                                                                        </Alert>
                                                                    ) : (
                                                                        <Alert severity="error">Please enter Password.</Alert>
                                                                    )}
                                                                </>
                                                            ) : null}
                                                            {inactiveuser ? (
                                                                <Alert severity="error">
                                                                    User Inactive. Please Contact Admin
                                                                </Alert>
                                                            ) : null}
                                                            {ifnotuser ? (
                                                                <Alert severity="error">User does not Exist.</Alert>
                                                            ) : null}
                                                            <Typography variant="h7" gutterBottom sx={{ mt: 2, textAlign: 'left', width: '100%' }}>
                                                                Enter username
                                                            </Typography>
                                                            <TextField
                                                                label="Username"
                                                                id="username"
                                                                name="username"
                                                                variant="outlined"
                                                                className="input"
                                                                InputLabelProps={{
                                                                    style: { fontSize: 13 },
                                                                }}
                                                                fullWidth
                                                                error={emptyUsername}
                                                                value={credentials.username}
                                                                onChange={(e) => handleInputChangeCredentials(e)}
                                                                sx={{ mt: 1 }}
                                                            />
                                                            {/* <Grid container className="sign-up-spacing">
                                                            <Grid item xs={12} md={8} className="sign-up">
                                                                <Typography variant="body2">
                                                                    <a
                                                                        className="sign-up-link"
                                                                        onClick={handleSignUpClick}
                                                                    >
                                                                        Not a Registered User? Sign Up
                                                                    </a>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid> */}
                                                            <Button
                                                                type="submit"
                                                                sx={blueButtonStyle}
                                                                onClick={usernameloginbutton}
                                                            >
                                                                Next
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography variant="h7" gutterBottom sx={{ mt: 2, textAlign: 'left', width: '100%' }}>
                                                                Enter Password
                                                            </Typography>
                                                            {wrongPassword ? (
                                                                <>
                                                                    {sessionStorage.getItem("customerType") !==
                                                                        "Internal" && !loginbutton ? (
                                                                        <Alert severity="error">Wrong Username</Alert>
                                                                    ) : (
                                                                        <Alert severity="error">Wrong Password.</Alert>
                                                                    )}
                                                                </>
                                                            ) : null}
                                                            {emptyUsername || emptyPassword ? (
                                                                <>
                                                                    {sessionStorage.getItem("customerType") !==
                                                                        "Internal" && !loginbutton ? (
                                                                        <Alert severity="error">
                                                                            Please enter a Username
                                                                        </Alert>
                                                                    ) : (
                                                                        <Alert severity="error">Please enter Password.</Alert>
                                                                    )}
                                                                </>
                                                            ) : null}
                                                            {inactiveuser ? (
                                                                <Alert severity="error">
                                                                    User Inactive. Please Contact Admin
                                                                </Alert>
                                                            ) : null}
                                                            {ifnotuser ? (
                                                                <Alert severity="error">User does not Exist.</Alert>
                                                            ) : null}

                                                            <TextField
                                                                label="Password"
                                                                id="password"
                                                                name="password"
                                                                type={viewPassword ? "text" : "password"}
                                                                variant="outlined"
                                                                className="input"
                                                                fullWidth
                                                                error={emptyPassword}
                                                                value={credentials.password}
                                                                onChange={(e) => handleInputChangeCredentials(e)}
                                                                sx={{ mt: 1 }}
                                                                InputLabelProps={{
                                                                    style: { fontSize: 13 },
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={togglePasswordVisibility}
                                                                                edge="end"
                                                                                style={{
                                                                                    background: "transparent",
                                                                                    color: "inherit",
                                                                                    padding: "0%",
                                                                                    margin: "0%",
                                                                                }}
                                                                            >
                                                                                {viewPassword ? (
                                                                                    <VisibilityIcon />
                                                                                ) : (
                                                                                    <VisibilityOffIcon />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <Grid container className="links-spacing">
                                                                <Grid item className="forgot-password">
                                                                    <Typography variant="body2" style={{ textAlign: "left" }}>
                                                                        <a
                                                                            className="forgot-password-link"
                                                                            onClick={handleForgotPasswordClick}
                                                                        >
                                                                            Forgot Password?
                                                                        </a>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item className="login-other-acc">
                                                                    <Typography variant="body2" style={{ textAlign: "right" }}>
                                                                        <a
                                                                            className="login-other-acc-link"
                                                                            onClick={handleloginotheraccountClick}
                                                                        >
                                                                            Login other account
                                                                        </a>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Button
                                                                type="submit"
                                                                sx={blueButtonStyle}
                                                                onClick={navigatetoDashboard}
                                                            >
                                                                Login
                                                            </Button>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Typography variant="h7" gutterBottom sx={{ mt: 2, mb:1, textAlign:"left", width:"100%" }}>
                                                        {resetPassword ? "Enter new password" : wrongAuthentication ? "" : "Enter your registered email"}
                                                    </Typography>
                                                    {wrongAuthentication ? (
                                                        <Alert severity="error">
                                                            Authentication token not correct
                                                        </Alert>
                                                    ) : null}
                                                    {emptyEmail ? (
                                                        <Alert severity="error">Please enter an email.</Alert>
                                                    ) : null}
                                                    {inactiveuser ? (
                                                        <Alert severity="error">
                                                            User Inactive. Please Contact Admin.
                                                        </Alert>
                                                    ) : null}
                                                    {ifnotuser ? (
                                                        <Alert severity="error">User does not Exist.</Alert>
                                                    ) : null}
                                                    {!confirmUser ? (
                                                        <>
                                                            <TextField
                                                                label="Email"
                                                                id="email"
                                                                variant="outlined"
                                                                className="input"
                                                                fullWidth
                                                                name="email"
                                                                value={email}
                                                                error={emailError}
                                                                onChange={(e) => handleInputChange(e)}
                                                                helperText={emailError ? "Email is Invalid." : null}
                                                                sx={{ mt: 2 }}
                                                                InputLabelProps={{
                                                                    style: { fontSize: 13 },
                                                                }}
                                                            />
                                                            {/* <div className="forgot-password"> */}
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    textAlign: "right",
                                                                    justifyContent: "end",
                                                                    width:"100%"
                                                                }}
                                                            >
                                                                <a
                                                                    onClick={handleForgotPasswordClick}
                                                                >
                                                                    Login
                                                                </a>
                                                            </Typography>
                                                            {/* </div> */}

                                                            <Button
                                                                type="submit"
                                                                sx={blueButtonStyle}
                                                                onClick={(event) => sendEmail(event)}
                                                            >
                                                                Send Authentication Email
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {!resetPassword ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <TextField
                                                                        label="New Password"
                                                                        id="password"
                                                                        variant="outlined"
                                                                        className="input"
                                                                        fullWidth
                                                                        type="password"
                                                                        value={resetPasswordFields.password}
                                                                        name="password"
                                                                        error={PasswordError}
                                                                        onChange={(e) => handleInputChangePassword(e)}
                                                                        helperText={
                                                                            <FormHelperText style={{ color: "var(--fail-color)", fontSize: '0.6rem' }}>
                                                                                {PasswordError ? "Your password must be have at least 8 characters long 1 uppercase & 1 lowercase character 1 number" : null}
                                                                            </FormHelperText>
                                                                        }
                                                                        InputLabelProps={{
                                                                            style: { fontSize: 13 },
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        label="Confirm New Password"
                                                                        id="confirmPassword"
                                                                        variant="outlined"
                                                                        className="input"
                                                                        fullWidth
                                                                        type="password"
                                                                        name="confirmPassword"
                                                                        value={resetPasswordFields.confirmPassword}
                                                                        error={isValid}
                                                                        onChange={(e) => handleInputConfirmPassword(e)}
                                                                        helperText={
                                                                            <FormHelperText style={{ color: "var(--fail-color)", fontSize: '0.6rem' }}>
                                                                                {isValid ? "Password Invalid / Doesn't match" : null}
                                                                            </FormHelperText>
                                                                        }
                                                                        InputLabelProps={{
                                                                            style: { fontSize: 13 },
                                                                        }}
                                                                    />
                                                                    {/* <div className="forgot-password"> */}
                                                                    <Typography
                                                                        variant="h7"
                                                                        sx={{
                                                                            textAlign: "right",
                                                                            justifyContent: "end",
                                                                            width:"100%"
                                                                        }}
                                                                    >
                                                                        <a
                                                                            onClick={handleForgotPasswordClick}
                                                                        >
                                                                            Login
                                                                        </a>
                                                                    </Typography>
                                                                    {/* </div> */}
                                                                    <Button
                                                                        type="submit"
                                                                        disabled={isValid || PasswordError || resetPasswordFields.confirmPassword == ""}
                                                                        onClick={(event) => resetPasswordFunction(event)}
                                                                        sx={{
                                                                            ...(!isValid && !PasswordError && !resetPasswordFields.confirmPassword == "") ?
                                                                                { ...blueButtonStyle } : { ...greyButtonStyle }
                                                                        }}  //when password feild id empty
                                                                    >
                                                                        Reset
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </form>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <form>
                                                <div className="form-grid">
                                                    {/* <Typography variant="h6" textAlign="center" sx={{mt:1}}>
                                                    Login as
                                                </Typography> */}
                                                </div>
                                                {/*wrong phone number case*/}
                                                <Typography variant="h7" gutterBottom sx={{ mt: 2 }}>
                                                    Sign in as
                                                </Typography>
                                                <FormControl fullWidth>
                                                    <div className="login-button-container">
                                                        <Grid
                                                            container
                                                            justifyContent="center"
                                                        >
                                                            <Grid item sx={{ mb: 1 }}>
                                                                <Button
                                                                    type="submit"
                                                                    className="login-button"
                                                                    onClick={internalclick}
                                                                    sx={{ ...blueButtonStyle }}
                                                                    style={{
                                                                        height: "100%",
                                                                        width: 10,
                                                                    }}
                                                                >
                                                                    Internal
                                                                </Button>
                                                            </Grid>
                                                            <Grid item sx={{ m: 1 }}></Grid>
                                                            <Grid item sx={{ mb: 1 }}>
                                                                <Button
                                                                    type="submit"
                                                                    sx={{ ...blueButtonStyle }}
                                                                    onClick={dealerclick}
                                                                    style={{
                                                                        height: "100%",
                                                                        width: 10,
                                                                    }}
                                                                >
                                                                    Dealer
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </FormControl>
                                            </form>
                                        </Grid>
                                    </>
                                )}
                            </>) : (<>
                                {/* <Grid container className="verification-container"> */}
                                {!otpClick ? (
                                    <Grid item xs={12} md={6}>
                                        <form>
                                            <div>
                                                <Typography variant="h7" gutterBottom sx={{ mt: 2,textAlign: 'left', width: '100%' }}>
                                                    Enter mobile number to verify with OTP
                                                </Typography>
                                            </div>
                                            <FormControl fullWidth sx={{ m: 1, mt:1 }}>
                                                <TextField
                                                    label="Phone Number"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    type="text"
                                                    variant="outlined"
                                                    className="input"
                                                    fullWidth
                                                    error={phoneNumberError}
                                                    onChange={(e) => handleInputPhoneNumber(e)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault(); // Stop the default behavior
                                                        }
                                                    }}
                                                    helperText={
                                                        phoneNumberError ? "Please enter a valid phone number." : null
                                                    }
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">+91</InputAdornment>
                                                        ),
                                                    }}
                                                    InputLabelProps={{
                                                        style: { fontSize: 13 },
                                                    }}
                                                />
                                                <Grid item className="login-other-acc">
                                                    <Typography variant="body2" style={{ textAlign: "right" }}>
                                                        <a
                                                            className="login-other-acc-link"
                                                            onClick={handleloginotheraccountClick}
                                                        >
                                                            Login other account
                                                        </a>
                                                    </Typography>
                                                </Grid>
                                            </FormControl>
                                            {/* <Typography
                                                variant="body2"
                                                style={{
                                                    textAlign: "right",
                                                    justifyContent: "end",
                                                    width: "96%",
                                                }}
                                            >
                                                <a
                                                    className="forgot-password-link"
                                                    onClick={setPartnerLogin(true)}
                                                >
                                                    Different login
                                                </a>
                                            </Typography> */}
                                            <div className="generate-button-container">
                                                <Button
                                                    type="button"
                                                    sx={blueButtonStyle}
                                                    className="generate-button"
                                                    onClick={(event) => getotpclick(event)}
                                                >
                                                    Generate OTP
                                                </Button>
                                            </div>
                                        </form>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} md={6}>
                                        <form>
                                            {/* <div>
              <Typography gutterBottom textAlign="center" variant="h7">
                We have sent a OTP to the mobile number provided
              </Typography>
            </div> */}
                                            <div>
                                                <Typography variant="h7" gutterBottom sx={{ mt: 2, textAlign: 'left'}}>
                                                We have sent a OTP to the mobile number provided. Enter the OTP below.
                                                </Typography>
                                            </div>
                                            {otpErrorMessage != "" ? (
                                                <Alert sx={{ m: 2 }} severity="error">
                                                    {otpErrorMessage}
                                                </Alert>
                                            ) : null}
                                            <div>
                                                <OtpInput
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={4}
                                                    type="number"
                                                    renderSeparator={<span>&nbsp;-&nbsp;</span>}
                                                    renderInput={(props) => <input {...props} />}
                                                    handleChange={handleOTPChange}
                                                    inputStyle="otp-input-feild"
                                                />
                                            </div>
                                            <div className="verify-button-container">

                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                >
                                                    <Grid item>
                                                        <Button
                                                            type="button"
                                                            // variant="contained"
                                                            // color="primary"
                                                            sx={{ ml: 1, ...blueButtonStyle }}
                                                            style={{ width: "100px" }}
                                                            className="resend-button"
                                                            onClick={handleresendclick}
                                                        >
                                                            Resend OTP
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            type="button"
                                                            style={{ width: "100px" }}
                                                            className="verify-button"
                                                            onClick={handleverifyclick}
                                                            sx={{ ml: 1, ...blueButtonStyle }}
                                                        >
                                                            Verify
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                {/* 
                                                <Button
                                                    type="button"
                                                    // variant="contained"
                                                    // color="primary"
                                                    sx={blueButtonStyle}
                                                    style={{ width: "100px" }}
                                                    className="resend-button"
                                                    onClick={handleresendclick}
                                                >
                                                    Resend OTP
                                                </Button>
                                                <Button
                                                    type="button"
                                                    style={{ width: "100px" }}
                                                    className="verify-button"
                                                    onClick={handleverifyclick}
                                                    sx={{ ml: 2, ...blueButtonStyle }}
                                                >
                                                    Verify
                                                </Button> */}
                                            </div>
                                        </form>
                                    </Grid>
                                )}
                                {/* </Grid> */}
                            </>)}
                        </form>
                    )}
                </div>
                <div className="toggle-container">
                    <div className="toggle">
                        <div
                            className={`toggle-panel toggle-left ${!isSignUp ? "active" : ""
                                }`}
                        >
                            <Typography variant="h4">Welcome Back!</Typography>
                            <Typography variant="body1">
                                To access all the features and enjoy a personalized experience,
                                please log in with your details.
                            </Typography>
                            <Button className="hidden" onClick={handleSignIn}>
                                Sign In
                            </Button>
                        </div>
                        <div
                            className={`toggle-panel toggle-right ${isSignUp ? "active" : ""
                                }`}
                        >
                            <Typography variant="h4">Hello!</Typography>
                            <Typography variant="body1">
                            Register with details to gain access to personalized insights tailored just for you. 
                            </Typography>
                            <Button className="hidden" onClick={handleSignUp}>
                                Sign Up
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
            </ThemeProvider>
        </div>
    );
}

export default Login2;
