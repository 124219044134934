import React from "react";
import { useNavigate } from "react-router-dom";
import "../Headers/header.css"; 
import corelogo from "./../../../Assets/corelogo.png";
import { clearPersistedState } from "../Apis/api";

const logout = (navigate, dispatch) => {
  sessionStorage.removeItem("jwt_token");
  sessionStorage.removeItem("EmailId");
  sessionStorage.removeItem("branch");
  sessionStorage.clear();
  dispatch(clearPersistedState());
  navigate("/");
};
export default logout;
