import React, { useState, useEffect } from "react";
import {Grid, Box, Alert, ThemeProvider, Typography} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCollectionsReport,
  getLoanCategoryStatus,
  getLoanCategories,
} from "../Common/Apis/api";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import logout from "../Common/Logout/logout";
import {DataGrids, theme} from "../../constant2";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
const BankRecon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const collectionsReportResponse = useSelector(
    (state) => state.DetailsReducers.collectionsReportResponse
  );
  const collectionsStatusReportResponse = useSelector(
    (state) => state.DetailsReducers.collectionsStatusReportResponse
  );
  const rows = [
    {
      id: 1,
      startDate: "12/12/2023",
      endDate: "19/12/2023",
      reconStatus: "Success",
      reconReason: "Pass",
    },
    {
      id: 2,
      startDate: "19/12/2023",
      endDate: "26/12/2023",
      reconStatus: "Failed",
      reconReason: "Amount Mismatch",
    },
    {
      id: 3,
      startDate: "26/12/2023",
      endDate: "2/12/2023",
      reconStatus: "Success",
      reconReason: "Pass",
    },
    {
      id: 4,
      startDate: "12/12/2023",
      endDate: "19/12/2023",
      reconStatus: "Success",
      reconReason: "Pass",
    },
    {
      id: 5,
      startDate: "19/12/2023",
      endDate: "26/12/2023",
      reconStatus: "Failed",
      reconReason: "Amount Mismatch",
    },
    {
      id: 6,
      startDate: "26/12/2023",
      endDate: "2/12/2023",
      reconStatus: "Success",
      reconReason: "Pass",
    },
    {
      id: 7,
      startDate: "26/12/2023",
      endDate: "2/12/2023",
      reconStatus: "Success",
      reconReason: "Pass",
    },
    {
      id: 8,
      startDate: "26/12/2023",
      endDate: "2/12/2023",
      reconStatus: "Success",
      reconReason: "Pass",
    },
  ];
  const columns = [
    { field: "id", headerName: "Id", width: 200 },
    { field: "startDate", headerName: "Start Date", width: 200 },
    { field: "endDate", headerName: "End Date", width: 200 },
    { field: "reconReason", headerName: "Recon reason", width: 250 },
    {
      field: "reconStatus",
      headerName: "Recon Status",
      width: 200,
      renderCell: (params) => (
        <div style={{ color: params.value === "Success" ? "var(--success-color)" : "var(--fail-color)" }}>
          {params.value}
        </div>
      ),
    },
  ];
  useEffect(() => {
    dispatch(getLoanCategories(handleLogoutButton));
  }, []);

  useEffect(() => {
    const accessLevel = levelsofAccess("Collection");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage( "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  useEffect(() => {
    if (
      collectionsReportResponse != undefined &&
      collectionsReportResponse != null
    ) {
      // setdata(collectionsReportResponse);
    }
  }, [collectionsReportResponse]);
  useEffect(() => {
    if (
      collectionsStatusReportResponse != undefined &&
      collectionsStatusReportResponse != null
    ) {
    }
  }, [collectionsStatusReportResponse]);
  return (
    <ThemeProvider theme={theme}>
    <div style={{ height: "78vh", overflow: "auto" }}>
      {showData ? (<>
      <Grid
        container
        sx={{ mt: 2 }}           //to remove x axis scroll
        style={{ textAlign: "center", justifyContent: "center" }}
      >
        <Typography variant="h6" gutterBottom>
          Bank Reconciliation
        </Typography>
      </Grid>
      <Grid container spacing={2} sx={12}>
        <Grid item xs={12} sx={{ mt: 1 }}>
          {/* <DataGrids rows={rows} columns={columns} page_size={6}/> */}
          <Box sx={{ height: "65vh", mt: 1 }}>
              <DataGrids rows={rows} columns={columns} page_size={10} />
            </Box>
        </Grid>
      </Grid>
        </>):(<><Grid item xs={12} md={12}>
            <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
          </Grid></>)}
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
    </ThemeProvider>
  );
};

export default BankRecon;
