import React, { useState, useEffect } from "react";
import { Grid, Button, LinearProgress, Box, Alert, Skeleton, ThemeProvider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import locale if needed
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logout from "../Common/Logout/logout";
import { Typography } from "@material-ui/core";
import { getReports } from "../Common/Apis/api";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import "./dealer.css";
import { getDealerLoanDetails, getTranchesDetails } from "../Common/Apis/api";
import { DataGrids , theme} from "../../constant2";
dayjs.locale("en");

const LoanDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [downloadReportLoading, setdownloadReportLoading] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [rows, setrows] = useState([]);

  const formatKey = (key) => {
    return key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase());
  };
  const reportTypeLoading = useSelector(
    (state) => state.DetailsReducers.reportTypeLoading
  );
  const checkBalance = async () => { };
  useEffect(() => {
    dispatch(getReports(handleLogoutButton));
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  const columns = [
    { field: "id", headerName: "TranchId", width: 150 },
    { field: "principal_amount", headerName: "Principal Amount", width: 150 },
    { field: "emi_amount", headerName: "EMI Amount", width: 150 },
    { field: "interest_amount", headerName: "Interest Amount", width: 150 },
    {
      field: "closing_principal_balance",
      headerName: "Net Disbursement",
      width: 150,
    },
    { field: "payment_date", headerName: "Expiry", width: 150 },
  ];
  const dealerLoanDetails = useSelector(
    (state) => state.DetailsReducers.dealerLoanDetailResponse
  );
  const dealerLoanDetailsLoading = useSelector(
    (state) => state.DetailsReducers.dealerLoanDetailLoading
  );
  const trancheDetails = useSelector(
    (state) => state.DetailsReducers.runningTrancheResponse
  );

  useEffect(() => {
    try {
      const dealerloandetailID = sessionStorage.getItem("mobile_number");
      dispatch(getDealerLoanDetails(dealerloandetailID, handleLogoutButton));
    } catch (e) { }
  }, []);

  useEffect(() => {
    try {
      if (dealerLoanDetails?.loan_id) {
        dispatch(
          getTranchesDetails(dealerLoanDetails?.loan_id, handleLogoutButton)
        );
      }
    } catch (e) { }
  }, [dealerLoanDetails]);

  useEffect(() => {
    setrows(trancheDetails?.joint_schedule);
  }, [trancheDetails]);
  return (
    <>
      <div>
      <ThemeProvider theme={theme}>
        {reportTypeLoading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : null}
        {downloadReportLoading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : null}
        {/* Breadcrumbs */}
        {dealerLoanDetails ? (
          <>
            <div style={{ height: "78vh", overflow: "auto" }}>
            <Grid container spacing={2} sx={12}>
              <Grid
                xs={12}
                sx={{ mt: 2, ml: 2, mr: 2}}>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "var(--primary-color)"
                  }}
                >
                  <AccountCircleIcon />
                  &nbsp;User Details
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mb:2, ml:2, height: "30vh" }}
                className="user-details"
              >
                <Grid
                  container
                  sx={{ ml: 1, mr: 1 }}
                  className="grid-container-element"
                >
                  {dealerLoanDetailsLoading ? (
                    <>
                      <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                    </>
                  ) : null}
                  {Object.keys(dealerLoanDetails).map((key) => key === "loan_amount" || key === "net_disbursement" ?
                    (<>
                      <Grid item xs={12} md={6} lg={6} className="grid-element">
                        <Typography component="div" style={{ fontSize: "0.9rem" }}>
                          <Typography
                            component="div"
                            style={{ fontSize: "0.9rem", display: "inline-block" }}
                          >
                            <b>{formatKey(key)}:&nbsp;</b>
                          </Typography>
                          ₹{parseFloat(
                            dealerLoanDetails[key]
                          ).toLocaleString("en-IN", {
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </Grid>
                    </>) : (
                      <Grid item xs={12} md={6} lg={6} className="grid-element">
                        <Typography component="div" style={{ fontSize: "1rem" }}>
                          <Typography
                            component="div"
                            style={{ fontSize: "0.9rem", display: "inline-block" }}
                          >
                            <b>{formatKey(key)}:&nbsp;</b>
                          </Typography>
                          {dealerLoanDetails[key]}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>

              {/* {trancheDetails ? (
                <> */}
              <Grid
                xs={12}
                sx={{ mt: 2, ml:2 }}
              // style={{ textAlign: "center", justifyContent: "center" }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    color: "var(--primary-color)"
                  }}
                >
                  <ShowChartIcon />
                  &nbsp;Running Tranches
                </Typography>
              </Grid>
              {/* <Grid> */}
              <Grid container sx={12}>
                {rows && rows.length > 0 ? (
                  <Box sx={{ m:2, height: "35vh", width: "100%" }}>
                    <DataGrids rows={rows} columns={columns} page_size={3} />
                  </Box>
                ) : null}
                {/* </Grid> */}
              </Grid>
              {/* </>
              ) : (
                <Grid
                  xs={12}
                  sx={{ m: 2, pt: 2 }}
                >
                  <Alert severity="warning">
                    No history of Tranches available
                  </Alert>
                </Grid>
              )} */}
              <Grid
                sx={{ m: 1, pr: 5 }}
                style={{ textAlign: "center", justifyContent: "right" }}
              >
                {/* <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={checkBalance}
                style={{
                  display: "inline",
                  textTransform: "none",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                Click here to find Overall Balance
              </Button> */}
              </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <Grid
            xs={12}
            sx={{ m: 2, pt: 2 }}
          ><Alert severity="warning">
              There is no user details available for the user.{" "}
            </Alert>
          </Grid>
        )}
        <ReusableSnackbar
          open={showSnackbar}
          onClose={closeSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
        </ThemeProvider>
      </div>
    </>
  );
};

export default LoanDetails;
