import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {Typography,ThemeProvider} from "@mui/material";
import Header from "../Common/Headers/header";
import "./dashboard.css";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import ApprovalIcon from "@mui/icons-material/Approval";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useNavigate } from "react-router-dom";
import { getUserCurrentRole } from "./../Common/Apis/api";
import ReusableSnackbar from "./../Common/Snackbar/snackbar";
import logout from "../Common/Logout/logout";
import { useDispatch } from "react-redux";
import { theme } from "../../constant2";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  sessionStorage.setItem("customerType", "Internal");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const gridItems = [
    {
      icon: <AssignmentIndIcon style={{ fontSize: "3rem" }} />,
      title: "Loan Onboarding",
      description:
        "Streamline loan onboarding with automated document verification and real-time approval processes.",
    },
    {
      icon: <CardTravelIcon style={{ fontSize: "3rem" }} />,
      title: "Portfolio’s",
      description:
        "Efficiently manage and assess loan portfolios in the loan origination process.",
    },
    {
      icon: <ApprovalIcon style={{ fontSize: "3rem" }} />,
      title: "Approvals and Disbursements",
      description:
        "Facilitate seamless financial processes with meticulous approvals and timely disbursements.",
    },
    {
      icon: <PaymentsIcon style={{ fontSize: "3rem" }} />,
      title: "Collections and Reports",
      description:
        "Optimize collections for streamlined loan origination and repayment management.",
    },
  ];
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await getUserCurrentRole(handleLogoutButton);
        sessionStorage.setItem("scope", response.data.scope);
      } catch (error) {}
    };

    getUser();
  }, []);
  const handleItemClick = (title) => {
    // Add your logic for handling the click event here
    if (title == "Loan Onboarding") {
      navigate("/LoanOnboarding");
    }
    if (title == "Approvals and Disbursements") {
      navigate("/Approvals");
    }
    if (title == "Portfolio’s") {
      navigate("/Portfolio");
    }
    if (title == "Collections and Reports") {
      navigate("/Collections");
    }
  };
  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return (
    <div>
    <ThemeProvider theme={theme}>
      <Header />
      <Grid
        container
        className="grid-container"
        style={{ width: "80%", margin: "auto" }}
      >
        {gridItems.map((item, index) => (
          <Grid key={index} item xs={12} md={6} lg={6} sx={{ p: 2 }}>
            <div
              className="grid-item"
              onClick={() => handleItemClick(item.title)}
            >
              <div className="icon">{item.icon}</div>
              <div className="text-container">
                <Typography variant="h6" className="title1">
                  {item.title}
                </Typography>
                <Typography variant="body1" className="description">
                  {item.description}
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default Dashboard;
