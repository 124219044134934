import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  CardContent,
  Card,
  LinearProgress,
  Box,
  Alert,
  ThemeProvider, 
  Typography,
} from "@mui/material";
import Header from "../Common/Headers/header";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import {
  getLoanReports,
  getLoanCategories,
  getPotfolioData,
} from "../Common/Apis/api";
import {Paper } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import Label from "@mui/icons-material/Label";
import ForumIcon from "@mui/icons-material/Forum";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import logout from "../Common/Logout/logout";
import { StyledTreeItem, blueButtonStyle, theme } from "../../constant2";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";

const PortfolioIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loanCategories = useSelector(
    (state) => state.DetailsReducers.loanCategoryResponse
  );
  const loanCategoriesLoading = useSelector(
    (state) => state.DetailsReducers.loanReportLoading
  );
  const portfolioReport = useSelector(
    (state) => state.DetailsReducers.portfolioDataResponse
  );
  const portfolioReportLoading = useSelector(
    (state) => state.DetailsReducers.portfolioDataLoading
  );
  const loanCategoryResponse = useSelector(
    (state) => state.DetailsReducers.loanCategoryResponse
  );
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [data, setdata] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const handleLoanClick = (loan, key) => {
    setSelectedNodeId(loan.id);
    dispatch(getPotfolioData(loan.id, key, handleLogoutButton));
    //setSelectedLoan(loan);
    sessionStorage.setItem("LoanCategory", loan.id);
    sessionStorage.setItem("key", key);
  };
  const navigatetoMoreDetails = (e) => {
    // sessionStorage.setItem("LoanCategory", selectedLoan.loan_category_id);
    navigate("../../PortfolioData");
  };
  useEffect(() => {
    //dispatch(getLoanReports(handleLogoutButton));
    dispatch(getLoanCategories(handleLogoutButton));
    // dispatch(getPotfolioData(1, handleLogoutButton));
  }, []);

  useEffect(() => {
    const accessLevel = levelsofAccess("PortFolio");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage("You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);


  useEffect(() => {
    if (loanCategories != undefined && loanCategories != null) {
      setdata(loanCategories);
      setSelectedLoan(loanCategories[0]);
      setSelectedNodeId(loanCategories[0]?.id);
    }
  }, [loanCategories]);
  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
      <Header />
      {loanCategoriesLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : null}
      {portfolioReportLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : null}
      {/* Breadcrumbs */}
      <Breadcrumbs style={{ position: "relative" }}>
        <Breadcrumb
          icon={<AssignmentIndIcon />}
          name="Dashboard"
          path="/Dashboard"
        />
        <Breadcrumb
          icon={<CardTravelIcon />}
          name="Portfolio"
          path="/Portfolio"
          isCurrentPage
        />
      </Breadcrumbs>
      <Grid container spacing={2}>
        {showData ? (<>
          {/* Left side - Loan Categories Menu */}
          <Grid item xs={1}></Grid>
          <Grid item xs={12} sx={{ mt: 4 }} md={3} lg={3}>
            <Paper elevation={3} style={{ padding: "10px", height: "70vh" }}>
              {loanCategoryResponse ? (
                <TreeView
                  aria-label="gmail"
                  defaultExpanded={["3"]}
                  defaultCollapseIcon={<ArrowDropDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                  sx={{
                    height: "66vh",
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: "auto",
                  }}
                >
                  {Object.keys(loanCategoryResponse).map((key) => (
                    <StyledTreeItem
                      key={key}
                      nodeId={key}
                      labelText={key}
                      labelIcon={Label}
                    >
                      {loanCategoryResponse[key].map((category) => (
                        <StyledTreeItem
                          key={category.id}
                          nodeId={`${key}-${category.id}`}
                          labelText={category.loan_category}
                          labelIcon={ForumIcon}
                          color="var(--styled-tree-text-color)"
                          bgColor="var(--styled-tree-bg-color)"
                          colorForDarkMode="var(--darkmode-styled-tree-text-color)"
                          bgColorForDarkMode="var(--darkmode-styled-tree-bg-color)"
                          onClick={() => handleLoanClick(category, key)}
                        />
                      ))}
                    </StyledTreeItem>
                  ))}
                </TreeView>
              ) : null}
            </Paper>
          </Grid>

          {/* Right side - Loan Details */}
          <Grid item xs={12} md={7} lg={7} sx={{ mt: 4 }}>
            <Paper
              elevation={0}
              style={{
                padding: "10px",
                height: "70vh",
                boxShadow: "none !important",
              }}
            >
              {portfolioReport ? (
                <>
                  <Grid xs={12}>
                    <Typography variant="h6">
                      Loan Category- {selectedLoan?.loan_category}
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 2, pl: 4, pb: 4 }}>
                    <Grid item xs={12} lg={4} md={4}>
                      <Card
                        variant="outlined"
                        style={{
                          height: "18vh",
                          background: "var(--primary-color)",
                          color: "var(--white-color)",
                        }}
                      >
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 16 }}
                            gutterBottom
                          >
                            #Total payments collected:
                          </Typography>
                          <Typography variant="h5" component="div">
                            {portfolioReport?.total_collected?.toLocaleString(
                              "en-IN"
                            )}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <Card
                        variant="outlined"
                        style={{
                          height: "18vh",
                          background: "var(--primary-color)",
                          color: "var(--white-color)",
                        }}
                      >
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 16 }}
                            gutterBottom
                          >
                          Payments failed:
                          </Typography>
                          <Typography variant="h5" component="div">
                            {portfolioReport?.payments_failed}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                      <Card
                        variant="outlined"
                        style={{
                          height: "18vh",
                          background: "var(--primary-color)",
                          color: "var(--white-color)",
                        }}
                      >
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 16 }}
                            gutterBottom
                          >
                          % of failed payments: 
                          </Typography>
                          <Typography variant="h5" component="div">
                            {portfolioReport?.failed_percent}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="right">
                    <Button
                      type="submit"
                      sx={blueButtonStyle}
                      onClick={(event) => navigatetoMoreDetails(event)}
                    >
                      More Details
                    </Button>
                  </Grid>
                </>
              ) : (
                <Typography>Select a loan category from the menu</Typography>
              )}
            </Paper>
          </Grid></>
        ) : (
          <><Grid item xs={12} md={12}>
            <Alert severity="error" sx={{ m: 5 }}>You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.</Alert>
          </Grid>
          </>)}
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default PortfolioIndex;
