import React, { useState } from "react";
import {
  TextField,
  Typography,
  Button,
  Grid,
  Alert,
  FormControl,
  InputAdornment
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "./../Common/Snackbar/snackbar";
import OtpInput from "react-otp-input";
import logout from "../Common/Logout/logout";
import { useDispatch } from "react-redux";
import "./verification.css";
import {
  generateotp,
  verifyotp,
  userLoginDealer
} from "../Common/Apis/api";
import { blueButtonStyle } from "../../constant2";

const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [otpClick, setOtpClick] = useState(false);
  const [otp, setOtp] = useState("");
  const [OTPerror, setOTPerror] = React.useState(false);
  const [invalidOtpCount, setInvalidOtpCount] = React.useState(0);
  const [otpErrorMessage, setOtpErrorMessage] = React.useState("");

  const handleOTPChange = (val) => {
    setOtp(val);
  };

  const getotpclick = async (event) => {
    event.preventDefault();
    if (phoneNumber !== "" && !phoneNumberError) {
      sessionStorage.setItem("mobile_number", phoneNumber);
      try {
        const result = await generateotp(phoneNumber);
        if (result) {
          setOtpClick(true);
        }
      } catch (error) { }
    }
  };

  const handleresendclick = async (event) => {
    event.preventDefault();
    if (phoneNumber !== "" && !phoneNumberError) {
      try {
        const result = await generateotp(phoneNumber);
        if (result?.data) {
          setOtpClick(true);
        }
      } catch (error) { }
    }
  };

  const handleverifyclick = async () => {
    setOTPerror(false);
    setOtpErrorMessage("");
    try {
      const result = await verifyotp(phoneNumber, otp);
      if (result.status == 200) {
        setOtpClick(true);
        const response = await userLoginDealer();
        setisAPILoading(false);
        if (response.status == 200) {
          sessionStorage.setItem("access_token", response.data.access_token);
          navigate("/landing");
        }
      }
    } catch (error) {
      setOtpErrorMessage("Incorrect OTP");
    }
  };

  const handleInputPhoneNumber = (e) => {
    setEmptyPhoneNumber(false);
    setPhoneNumber(e.target.value);
    const regex = /^[0-9]{10}$/;
    if (regex.test(e.target.value)) {
      setPhoneNumberError(false);
    } else {
      setPhoneNumberError(true);
    }
  };

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    setTimeout(() => {
      logout(navigate, dispatch);
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Grid container className="verification-container">
      <Grid item xs={12} md={6} className="blue-background">
        <div className="co-lending-container">
          <Typography variant="h4" className="co-lending-text">
            Lendsphere
          </Typography>
          <Typography variant="body1" className="tagline-text">
            Your Trusted Financial Partner
          </Typography>
        </div>
      </Grid>
      {!otpClick ? (
        <Grid item xs={12} md={6} className="verification-form">
          <form className="form-grid">
            <div>
              <Typography variant="h5" gutterBottom textAlign="center">
                Enter Mobile number to verify with OTP
              </Typography>
            </div>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                label="Phone Number"
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                variant="outlined"
                className="input"
                fullWidth
                error={phoneNumberError}
                onChange={(e) => handleInputPhoneNumber(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Stop the default behavior
                  }
                }}
                helperText={
                  phoneNumberError ? "Please enter a valid phone number." : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <div className="generate-button-container">
              <Button
                type="button"
                // variant="contained"
                // color="primary"
                sx={blueButtonStyle}
                className="generate-button"
                onClick={(event) => getotpclick(event)}
                // style={{
                //   textTransform: "none",
                //   backgroundColor: "var(--primary-color)",
                // }}
              >
                Generate OTP
              </Button>
            </div>
          </form>
        </Grid>
      ) : (
        <Grid item xs={12} md={6} className="verification-form">
          <form className="form-grid">
            <div>
              <Typography gutterBottom textAlign="center" fontSize={"0.9em"}>
                We have sent a OTP to the mobile number provided
              </Typography>
            </div>
            <div>
              <Typography variant="h5" gutterBottom textAlign="center">
                Enter the OTP below
              </Typography>
            </div>
            {otpErrorMessage != "" ? (
              <Alert sx={{ m: 2 }} severity="error">
                {otpErrorMessage}
              </Alert>
            ) : null}
            <div className="otp-feild">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>&nbsp;-&nbsp;</span>}
                renderInput={(props) => <input {...props} />}
                handleChange={handleOTPChange}
                inputStyle="otp-input"
              />
            </div>
            <div className="verify-button-container">
              <Button
                type="button"
                // variant="contained"
                // color="primary"
                sx={blueButtonStyle}
                className="resend-button"
                onClick={handleresendclick}
                // style={{
                //   textTransform: "none",
                //   backgroundColor: "var(--primary-color)",
                // }}
              >
                Resend OTP
              </Button>
              <Button
                type="button"
                // variant="contained"
                // color="primary"
                className="verify-button"
                onClick={handleverifyclick}
                sx={{ ml: 2, ...blueButtonStyle }}
                // style={{
                //   textTransform: "none",
                //   backgroundColor: "var(--primary-color)",
                // }}
              >
                Verify
              </Button>
            </div>
          </form>
        </Grid>
      )}
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Grid>
  );
};
export default Verification;
