import React, { useState, useEffect } from "react";
import {Grid, CardContent, Card, Button, Alert, LinearProgress, Box, FormHelperText, ThemeProvider} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserCurrentRole,
  getReloadTranchesDetails,
  reloadRequest,
} from "../Common/Apis/api";
import { Typography, Paper, TextField } from "@material-ui/core";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import logout from "../Common/Logout/logout";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import "./dealer.css";
import { NumericFormatCustom, greyButtonStyle, blueButtonStyle, theme } from "../../constant2";
const ReloadReq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [reloadLimit, setreloadLimit] = useState(0);
  const [error, setError] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [reloadAmount, setReloadAmount] = useState("");
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [reloadSuccessful, setreloadSuccessful] = React.useState(false);
  const dealerLoanDetails = useSelector(
    (state) => state.DetailsReducers.dealerLoanDetailResponse
  );
  const getReloadTranchesDetails = useSelector(
    (state)=>state.DetailsReducers.TrancheDetailResponse
  )
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await getUserCurrentRole(handleLogoutButton);
        sessionStorage.setItem("scope", response.data.scope);
      } catch (error) { }
    };
    getUser();
  }, []);

  const getReloadAmount = async () => {
    try {
      const response = await getReloadTranchesDetails(
        dealerLoanDetails?.loan_id,
        handleLogoutButton
      );
      if (response) {
        // console.log("Response is", response);
        setreloadLimit(response.data.reload_limit);
      }
    } catch (error) {
      // console.log("error", error.message);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      getReloadAmount();
    };
    getUser();
  }, []);

  const handleReloadAmountChange = (event) => {
    const value = event.target.value;
    setReloadAmount(value);
    const reloadLimitString = reloadLimit.toString(); // Convert reloadLimit to string
    if (
      value &&
      parseInt(value.replace(/,/g, "")) >
      parseInt(reloadLimitString.replace(/,/g, ""))
    ) {
      setError("Reload amount cannot exceed remaining amount available");
    } else {
      setError("");
    }
  };
  const reloadTrancheRequest = async () => {
    try {
      setisAPILoading(true);
      const response = await reloadRequest(
        dealerLoanDetails?.loan_id,
        reloadAmount,
        handleLogoutButton
      );
      if (response) {
        setreloadSuccessful(true);
        setisAPILoading(false);
        getReloadAmount();
      }
    } catch (error) {
      setisAPILoading(false);
      // console.log("error", error.message);
    }
  };
  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
      {isAPILoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : null}
      {reloadSuccessful ? (
        <Alert severity="success">Reload Successful!</Alert>
      ) : null}
      <div style={{ height: "78vh", overflow: "auto" }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        overflow="auto"
      >
       {/* {getReloadTranchesDetails ?(<> */}
        {/* <Grid container spacing={2} sx={{ ml: 4, mr: 4, mt: 10, width: "60%" }}> */}
        <Box sx={{ ml: 4, mr: 4, mt: 7, width: "60%" }}>
          <Card variant="outlined" style={{ height: "40%", width: "100%"}}> {/*add overflow:"auto" if required*/}
            <CardContent>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                justifyContent="center"
                alignItems="center"
                sx={{ paddingTop: "15vh" }}
              >
                <Typography align="center" style={{ fontSize: "1.7rem" }}>
                  <Typography
                    className="reloadreqtext"
                    sx={{ minWidth: "300px", mr: "10px" }}
                    component="div"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "600",
                      display: "inline-block",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Remaining Amount Available:&nbsp;
                  </Typography>
                  {reloadLimit.toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                justifyContent="center"
                alignItems="flex-start"
                sx={{ paddingBottom: "20vh" }}
              >
                <TextField
                  style={{ marginTop: "1%", width: "50%" }}
                  label="Amount to reload"
                  variant="outlined"
                  value={reloadAmount}
                  onChange={handleReloadAmountChange}
                  error={!!error}
                  helperText={
                  <FormHelperText style={{ color:"var(--fail-color)",fontSize: '0.6rem' }}>
                  {error ? 'Reload amount cannot exceed remaining amount available' : ''}
                </FormHelperText>
                }
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
                {/* </Typography> */}
              </Grid>
            </CardContent>
          </Card>
          {/* </Grid> */}
          
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ pr: 10, mt: 8 }}
          style={{ textAlign: "center", justifyContent: "flex-end" }}
        >
          <Button
            sx={{ pl: 3, pr: 3, ...(error != "" || reloadAmount == "" ? { ...greyButtonStyle } : { ...blueButtonStyle }) }}
            disabled={error != "" || reloadAmount == ""}
            onClick={() => reloadTrancheRequest()}
          >
            Reload
          </Button>
        </Grid>
        {/* </>
      ):(
        <Grid
        xs={12}
        sx={{ m: 2, pt: 2 }}
      >
        <Alert severity="warning">Unable to reload</Alert>
        </Grid>
      )} */}
        {/* </Box> */}
      </Grid>
      </div>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default ReloadReq;
