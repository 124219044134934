import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Login/login";
import SignUp from "./Components/SignUp/signup";
import Verification from "./Components/Verification/verification";
import Landing from "./Components/Dealer/landing";
import Dashboard from "./Components/Dashboard/dashboard";
import UploadDataComponent from "./Components/Loan Onboarding/uploadData";
import Index from "./Components/ApprovalsAndDisbursements/index";
import DetailsTableView from "./Components/ApprovalsAndDisbursements/detailTableView";
import Schedule from "./Components/ApprovalsAndDisbursements/schedule";
import PortfolioIndex from "./Components/Portfolio/portfolioindex";
import Portfolio from "./Components/Portfolio/Portfolio";
import Collections from "./Components/Collections";
import UploadDataComponentCollections from "./Components/Collections/uploadData";
import ConfirmDisbursement from "./Components/ApprovalsAndDisbursements/confirmDisbursements";
import DisbursementDetails from "./Components/ApprovalsAndDisbursements/disbursementDetails";
import LandingPage from "./Components/LandingPage/index";
import ErrorPage from "./Components/Common/ErrorPage/errorPage";
import Login2 from "./Components/Login2/login2"; 
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/login2" element={<Login2/>}/>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/Verification" element={<Verification />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/LoanOnboarding" element={<UploadDataComponent />} />
        <Route path="/Approvals" element={<Index />} />
        <Route path="/DetailView" element={<DetailsTableView />} />
        <Route path="/Schedule" element={<Schedule />} />
        <Route path="/Portfolio" element={<PortfolioIndex />} />
        <Route path="/PortfolioData" element={<Portfolio />} />
        <Route path="/Collections" element={<Collections />} />
        <Route path="/DisbursementDetails" element={<DisbursementDetails />} />
        <Route
          path="/UploadDataCollections"
          element={<UploadDataComponentCollections />}
        />
        <Route path="/ConfirmDisbursement" element={<ConfirmDisbursement />} />
      </Routes>
    </Router>
  );
}

export default App;
