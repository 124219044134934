import React, { useRef } from "react";
import "./Testimonials.css";
import next_icon from "../../../Assets/next-icon.png";
import back_icon from "../../../Assets/back-icon.png";
import user_1 from "../../../Assets/user-1.png";
import user_2 from "../../../Assets/kpm.png";
import user_3 from "../../../Assets/user-3.png";
import user_4 from "../../../Assets/user-4.png";

const Testimonials = () => {
  const slider = useRef();
  let tx = 0;

  const slideForward = () => {
    if (tx > -50) {
      tx -= 25;
    }
    slider.current.style.transform = `translateX(${tx}%)`;
  };
  const slideBackward = () => {
    if (tx < 0) {
      tx += 25;
    }
    slider.current.style.transform = `translateX(${tx}%)`;
  };

  return (
    <div className="testimonials">
      <img src={next_icon} alt="" className="next-btn" onClick={slideForward} />
      <img
        src={back_icon}
        alt=""
        className="back-btn"
        onClick={slideBackward}
      />
      <div className="slider">
        <ul ref={slider}>
          <li>
            <div className="slide">
              <div className="user-info">
                <img src={user_1} alt="" />
                <div>
                  <h3>Gosree Finance</h3>
                  <span>Kerela, India</span>
                </div>
              </div>
              {/* <p>
                Lendsphere has simplified my lending process immensely. With its
                digital platform and automated approval system, I can approve
                and disburse loans within minutes, saving both time and
                resources.
              </p> */}
            </div>
          </li>
          <li>
            <div className="slide">
              <div className="user-info">
                <img src={user_2} alt="" />
                <div>
                  <h3>KPM</h3>
                  <span>Kerela, India</span>
                </div>
              </div>
              {/* <p>
                Fractional loan scheduling offered by Lendsphere is a
                game-changer. It allows me to schedule repayments according to
                the portion of the loan I've provided, ensuring fair and
                efficient distribution of funds.
              </p> */}
            </div>
          </li>
          <li>
            <div className="slide">
              <div className="user-info">
                <img src={user_3} alt="" />
                <div>
                  <h3>Petromoney</h3>
                  <span>Chennai, India</span>
                </div>
              </div>
              {/* <p>
                The customizability of Lendsphere is impressive. It seamlessly
                integrates with my existing systems through flexible APIs,
                allowing me to tailor the platform to meet my specific lending
                needs.
              </p> */}
            </div>
          </li>
          <li>
            <div className="slide">
              <div className="user-info">
                <img src={user_4} alt="" />
                <div>
                  <h3>Samudra</h3>
                  <span>Kerela, India</span>
                </div>
              </div>
              {/* <p>
                Lendsphere's collaborative approach has expanded my lending
                capacity while diversifying risk. By sharing data and resources
                with partner lending companies, I can optimize my lending
                portfolio and achieve better outcomes for both borrowers and
                lenders.
              </p> */}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Testimonials;
