import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Typography, Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { disburseLoan, getLoanReportsDetails } from "../Common/Apis/api";
import confirmImage from "./../../Assets/confirm.png";
import Grid from "@mui/material/Grid";
import ReusableSnackbar from "./../Common/Snackbar/snackbar";
import logout from "../Common/Logout/logout";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DisbursementDetailsPopup = ({ isOpen, onClose, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const handleConfirm = () => {
    // Add your logic for disbursement confirmation here
    dispatch(disburseLoan(data.id, handleLogoutButton));
    onClose();
  };
  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return (
    <>
      <BootstrapDialog onClose={onClose} open={isOpen}>
        <DialogContent className="disbursementDetailsPopup">
          <Typography
            variant="h6"
            component="div"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            Disbursement Details
          </Typography>
          <Grid container style={{ justifyContent: "center" }}>
            <img
              src={confirmImage}
              alt="Colending"
              style={{ height: "15vh" }}
            />
          </Grid>

          <Typography
            variant="h6"
            component="div"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            Confirm the disbursement?
          </Typography>
          {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          <Typography variant="body1">
            <strong>Customer Name:</strong> {data.customer_name}
          </Typography>
          <Typography variant="body1">
            <strong>Tenure:</strong> {data.tenure}
          </Typography>
          <Typography variant="body1">
            <strong>Loan Amount:</strong> {data.loan_amount}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            style={{ backgroundColor: "var(--primary-color)" }}
          >
            Confirm Disbursement
          </Button>
          <Button onClick={onClose} color="default" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default DisbursementDetailsPopup;
