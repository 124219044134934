//-------------------THIS FILE CONTAINS ALL THE REUSABLE COMPONENTS---------------------
import React from "react";
import { NumericFormat } from "react-number-format";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Grid, CardContent, Card } from "@mui/material";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import LoanDetails from "./Components/Dealer/loandetails";
import TranchReq from "./Components/Dealer/tranchreq";
import ReloadReq from "./Components/Dealer/reloadreq";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AddCardIcon from "@mui/icons-material/AddCard";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import BIReport from "./Components/Collections/bireport";
import FinancialReports from "./Components/Collections/financialReports";
import BankRecon from "./Components/Collections/bankRecon";
import UploadDataComponentCollections from "./Components/Collections/uploadData";
import { createTheme } from '@mui/material/styles';
//------------------------------------------------------------------------------- CHANGE GLOBAL MUI THEMES
export const theme = createTheme({
    typography: {
        fontFamily: [
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'inherit',
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'inherit',
                }
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'inherit',
                }
            },
        },
        MuiDropzoneArea: {                //drop zone area theme
            styleOverrides: {
                ".MuiDropzoneArea-text": {
                    fontFamily: 'inherit',
                }
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'inherit',
                }
            },
        },
        MuiTextField: {                 //textfeilds
            styleOverrides: {
                root: {
                    fontFamily: 'inherit',
                }
            },
        },
    },
});
//------------------------------------------------------------------------------- REUSABLE DATAGRID
export const DataGrids = ({ rows, columns, page_size }) => {
    return (
        <DataGrid
            rows={rows}
            columns={columns}
            rowHeight={45} // for row height
            columnHeaderHeight={34} // for columnHeader height
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: page_size,
                    },
                },
            }}
            pageSizeOptions={[page_size]}
            // hideFooter={true}
            // apiRef={gridApiRef}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                },
            }}
            sx={{
                userSelect: "none", // Chrome and Opera
                WebkitUserSelect: "none", // Safari and newer versions of IOS Safari
                MozUserSelect: "none", // Firefox
                msUserSelect: "none", // Internet Explorer/Edge
                cursor: "default",
                backgroundColor: "#ffffff",
                "& .MuiDataGrid-cell": {
                    borderRight: "1px solid #e0e0e0",
                    fontSize: "12px",
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ffffff",
                    color: "#7d7d7d",
                    fontSize: "12px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "bold",
                },
                '.css-1rtad1': {
                    position: 'absolute',
                    zIndex: 6,
                    '--size': 'calc(max(var(--DataGrid-scrollbarSize), 14px))',
                    width: '100%',
                    height: 'var(--size)',
                    overflowY: 'hidden',
                    overflowX: 'auto',
                    outline: 0,
                    bottom: 0,
                    left: 0,
                },
            }}
        />
    );
};
//------------------------------------------------------------------------------- DATE FORMATS IN COLUMN
export const DateRenderCell = (params) => {
    const date = new Date(params.value);
    const formattedDate = date.toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
    return <div>{formattedDate}</div>;
}
//------------------------------------------------------------------------------- STATUS FORMATS IN COLUMN
export const StatusRenderCell = (params) => {
    let statusText = "";
    let icon = null;
    let color = "";
    let background = "";
    // if (!params.row.esign_status) {
    //   return <div>-</div>; // Render a dash if Esign Status is false
    // }
    switch (params.value) {
        case true:
            statusText = "Success";
            color = "var(--success-color)";
            background = "var(--success-bgcolor)";
            break;
        case false:
            statusText = "Failed";
            color = "var(--fail-color)";
            background = "var(--fail-bgcolor)";
            break;
        case "Pending":
            statusText = "Pending";
            color = "var(--primary-color)";
            background = "var(--pending-bgcolor)";
            break;
        default:
            break;
    }
    return (
        <span style={{ color: color, background: background, borderRadius: "5px", padding: "3px", fontSize: "13px", width: "5em", textAlign: "center" }}>
            {statusText}
        </span>
    );
}
//------------------------------------------------------------------------------- AMOUNT TEXTFEILD FORMATS
export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref
) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            value={props.value}
            displayType="input"
            thousandSeparator={true}
            thousandsGroupStyle="lakh"
            allowNegative={false}
            prefix="₹"
        />
    );
});
//------------------------------------------------------------------------------- MENU ITEMS MAPPING
export const ComponentMapping = {
    "Loan Details": <LoanDetails />,
    "Tranche Request": <TranchReq />,
    "Reload Requirement": <ReloadReq />,
    "Financial Reporting": <BIReport />,
    "BI Report": <BIReport />,
    "Financial Report": <FinancialReports />,
    "Bank Recon": <BankRecon />,
    "Upload Collections Data": <UploadDataComponentCollections />,
};

export const DealerMenu = [
    { id: "1", labelText: "Loan Details", icon: ReceiptLongIcon, },
    { id: "2", labelText: "Tranche Request", icon: RequestQuoteIcon, },
    { id: "3", labelText: "Reload Requirement", icon: AddCardIcon, }
];

export const CollectionsMenu = [
    {
        id: "1",
        labelText: "Financial Reporting",
        icon: AssessmentRoundedIcon,
        children: [
            { id: "2", labelText: "BI Report", icon: EqualizerIcon },
            { id: "3", labelText: "Financial Report", icon: UploadFileIcon },
        ],
    },
    { id: "4", labelText: "Bank Recon", icon: AccountBalanceRoundedIcon },
    { id: "5", labelText: "Upload Collections Data", icon: UploadFileRoundedIcon },
];
//------------------------------------------------------------------------------- STYLED TREE ITEM LEFT COMPONENT
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        "&.Mui-expanded": {
            fontWeight: theme.typography.fontWeightRegular,
        },
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: "var(--tree-view-color)",
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: "inherit",
            color: "inherit",
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

export const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps = {
        "--tree-view-color":
            theme.palette.mode !== "dark" ? color : colorForDarkMode,
        "--tree-view-bg-color":
            theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography
                        variant="body2"
                        sx={{ fontWeight: "inherit", flexGrow: 1 }}
                    >
                        <span>{labelText}</span>
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        <span>{labelInfo}</span>
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});
//------------------------------------------------------------------------------- LEFT SIDE MENU

export const LeftPanelComponent = ({ menuItems, componentMapping }) => {
    const [selectedMenu, setSelectedMenu] = React.useState(
        menuItems[0].labelText
    );
    const handleMenuSelect = (menu) => {
        setSelectedMenu(menu);
    };

    return (
        <>
            <Grid item xs={12} md={3} lg={3}>
                <Card variant="outlined" style={{ height: "98%" }} sx={{ ml: 2 }}>
                    <CardContent>
                        <TreeView
                            aria-label="menu"
                            defaultExpanded={["1"]}
                            defaultCollapseIcon={<ArrowDropDownIcon />}
                            defaultExpandIcon={<ArrowRightIcon />}
                            defaultEndIcon={<div style={{ width: "24vh" }} />}
                            sx={{
                                height: 264,
                                flexGrow: 1,
                                maxWidth: 400,
                                overflowY: "auto",
                            }}
                        >
                            {menuItems.map((item) => (
                                <StyledTreeItem
                                    key={item.id}
                                    nodeId={item.id}
                                    labelText={item.labelText}
                                    labelIcon={item.icon}
                                    color="var(--styled-tree-text-color)"
                                    bgColor="var(--styled-tree-bg-color)"
                                    colorForDarkMode="var(--darkmode-styled-tree-text-color)"
                                    bgColorForDarkMode="var(--darkmode-styled-tree-bg-color)"
                                    onClick={() => handleMenuSelect(item.labelText)}
                                    selected={selectedMenu === item.labelText}
                                >
                                    {item.children && item.children.map((child) => (
                                        <StyledTreeItem
                                            key={child.id}
                                            nodeId={child.id}
                                            labelText={child.labelText}
                                            labelIcon={child.icon}
                                            color="var(--styled-tree-text-color)"
                                            bgColor="var(--styled-tree-bg-color)"
                                            colorForDarkMode="var(--darkmode-styled-tree-text-color)"
                                            bgColorForDarkMode="var(--darkmode-styled-tree-bg-color)"
                                            onClick={() => handleMenuSelect(child.labelText)}
                                            selected={selectedMenu === child.labelText}
                                        />
                                    ))}
                                </StyledTreeItem>
                            ))}
                        </TreeView>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={9} lg={9}>
                <Card variant="outlined" style={{ height: "98%" }} sx={{ mr: 2 }}>
                    <CardContent>{componentMapping[selectedMenu]}</CardContent>
                </Card>
            </Grid>
        </>
    );
};
//------------------------------------------------------------------------------- BUTTON STYLES
export const blueButtonStyle = {
    "&.MuiButton-text": {
        backgroundColor: "var(--primary-color)",
        color: "#ffffff",
        textTransform: "none",
    },

    "&.MuiButton-text:hover": {
        backgroundColor: "#0076c9 ",
    },
    whiteSpace: "nowrap",
};
export const greyButtonStyle = {
    "&.MuiButton-text": {
        backgroundColor: "#d9d9d9",
        color: "#000000",
        textTransform: "none",
    },

    "&.MuiButton-text:hover": {
        backgroundColor: "#999999",
    },
    whiteSpace: "nowrap",
};

//------------------------------------------------------------------------------- NEW
