import { jwtDecode } from "jwt-decode";

export const levelsofAccess = (context) => {
  const access_token = sessionStorage.getItem("access_token");
  // console.log("Token", access_token);
  if (!access_token) {
    return null;
  }
  if (access_token) {
    const token_decode = jwtDecode(access_token);
    // console.log(token_decode);
    const permissions = token_decode?.permissions || [];
  
    const getAccessLevel = (context) => {
      const viewPermission = `${context}-View`;
      const editPermission = `${context}-Edit`;

      if (permissions.includes(editPermission)) {
        return "Edit";
      } else if (permissions.includes(viewPermission)) {
        return "View";
      } else {
        return null; // No access
      }
    };

    return getAccessLevel(context);
  }
};
