import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import {Typography,ThemeProvider} from "@mui/material";
import { styled } from "@mui/system";
import useTheme from "@mui/system/useTheme";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../constant2";

const StyledLink = styled(Link)(({ theme }) => ({
  color: "#000000",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  cursor: "pointer", // Add cursor: pointer for styling
}));

const Breadcrumb = ({ icon, name, path, isCurrentPage }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigation = (e) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <>
    <ThemeProvider theme={theme}>
      <div style={{ marginTop: "10px" }}>
        {isCurrentPage ? (
          <Typography color="textSecondary" sx={{ margin: theme.spacing(1) }}>
            {icon &&
              React.cloneElement(icon, {
                sx: {
                  marginRight: 0.5,
                  width: 20,
                  height: 20,
                  verticalAlign: "middle",
                },
              })}
              {name}
          </Typography>
        ) : (
          <StyledLink
            onClick={handleNavigation}
            underline="hover"
            theme={theme}
          >
            {icon &&
              React.cloneElement(icon, {
                sx: {
                  marginRight: 0.5,
                  width: 20,
                  height: 20,
                  verticalAlign: "middle",
                },
              })}
            <Typography color="inherit">{name}</Typography>
          </StyledLink>
        )}
      </div>
      </ThemeProvider>
    </>
  );
};

export default Breadcrumb;
