import React from "react";
import { Grid, Typography, Button, ThemeProvider } from "@mui/material";
import logout from "../Logout/logout";
import { blueButtonStyle } from "../../../constant2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import HomeIcon from '@mui/icons-material/Home';
import { theme } from "../../../constant2";
import "./errorPage.css";

const ErrorPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const text = "Sorry, the page not found. \n The Page you are looking for doesn't exist or an other error occurred."
    return (
        <>
        <ThemeProvider theme={theme}>
            <Grid xs={12} className="blue_background" sx={{ height: "100vh" }}>
                {/* <img src={errorimg} alt="404 error" sx={{height:"100%", width:}}/> */}
                <Grid container sx={{ alignContent: "flex-start" }}>
                    <Grid item xs={12} >
                        <Typography
                            sx={{ mt: 20 }}
                            style={{ color: "var(--primary-color)", fontSize: "6em", opacity: "75%", textAlign: "center" }}
                        >Error: 404
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            style={{ color: "var(--primary-color)", fontSize: "3em", textAlign: "center" }}
                        >Page Not Found
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{ mt: 1 }}
                            style={{ color: "var(--primary-color)", fontSize: "1em", whiteSpace: "pre-line", textAlign: "center" }}
                        >
                            {text}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}
                        sx={{ display: "flex", justifyContent: "center", pb:5 }}>
                        <Button
                            sx={{mt: 2, ...blueButtonStyle }}
                            onClick={() => logout(navigate, dispatch)}
                        >
                            <HomeIcon />&nbsp; Return home
                        </Button>
                    </Grid>
                </Grid>
                <Grid>
                </Grid>
            </Grid>
            </ThemeProvider>
        </>
    );
};
export default ErrorPage;