import React, { useState, useEffect } from "react";
import {Grid, ThemeProvider} from "@mui/material";
import Header from "../Common/Headers/header";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  getLoanSchedule,
} from "../Common/Apis/api";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import Alert from "@mui/material/Alert";
import logout from "../Common/Logout/logout";
import {DataGrids, theme} from "../../constant2";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import CustomLoader from "../Common/CustomLoader/corestratLoader";

const Schedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loanSchedule = useSelector(
    (state) => state.DetailsReducers.loanScheduleResponse
  );
  const loanScheduleLoading = useSelector(
    (state) => state.DetailsReducers.loanScheduleLoading
  );
  const [data, setdata] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const scope = sessionStorage.getItem("scope");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");

  let columns = [
    {
      field: "emi",
      headerName: "EMI",
      width: 300,
      valueGetter: (params) =>
        params.value?.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
  ];

  if (scope === "colender") {
    columns = [
      ...columns,
      {
        field: "100% Joint Schedule",
        headerName: "100% Joint Schedule",
        width: 300,
        valueGetter: (params) =>
          params.value?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          }),
      },
      {
        field: "80% Colender",
        headerName: "80% Colender",
        width: 300,
        valueGetter: (params) =>
          params.value?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          }),
      },
    ];
  } else if (scope === "originator") {
    columns = [
      ...columns,
      {
        field: "100% Joint Schedule",
        headerName: "100% Joint Schedule",
        width: 300,
        valueGetter: (params) =>
          params.value?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          }),
      },
      {
        field: "20% Originator",
        headerName: "0% Originator",
        width: 300,
        valueGetter: (params) =>
          params.value?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          }),
      },
    ];
  }

  // Handle the button click event
  const handleScheduleClick = (row) => {
    // Add your logic for handling the schedule button click
  };
  const handleEditDisbursement = (row) => {
    // Add your logic for handling the edit icon click
  };
  const transformData = (joint, partner, nbfc) => {
    const result = [];
    if (joint != undefined && joint != null) {
      for (let i = 0; i < joint.length; i++) {
        const id = joint[i]?.id;
        const emi = joint[i]?.emi_amount;
        const jointPercentage = joint[i]?.emi_amount;
        const partnerPercentage = partner[i]?.emi_amount;
        const nbfcPercentage = nbfc[i]?.emi_amount;

        const row = {
          id: `row_${id}`, // Unique identifier for each row
          emi: emi,
          ["100% Joint Schedule"]: jointPercentage,
          ["80% Colender"]: nbfcPercentage,
          ["20% Originator"]: partnerPercentage,
        };

        result.push(row);
      }
    }
    return result;
  };
  useEffect(() => {
    try {
      const loanScheduleID = sessionStorage.getItem("LoanScheduleId");
      dispatch(getLoanSchedule(loanScheduleID, handleLogoutButton));
    } catch (e) {}
  }, []);
  useEffect(() => {
    if (loanSchedule != undefined && loanSchedule != null) {
      const transformedData = transformData(
        loanSchedule.joint_schedule,
        loanSchedule.partner_schedule,
        loanSchedule.nbfc_schedule
      );

      setdata(transformedData);
    }
  }, [loanSchedule]);
  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };

  useEffect(() => {
    const accessLevel = levelsofAccess("Disbursement");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage( "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return (
    <div>
    <ThemeProvider theme={theme}>
      <Header />
      {loanScheduleLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      {/* Breadcrumbs */}
      <Breadcrumbs style={{ position: "relative" }}>
            <Breadcrumb
                icon={<AssignmentIndIcon />}
                name="Dashboard"
                path="/Dashboard"
            />
            <Breadcrumb
                icon={<CardTravelIcon />}
                name="Approvals and Disbursements"
                path="/Approvals"
            />
            <Breadcrumb
                icon={<FileCopyIcon />}
                name="Details"
                path="/DetailView"
            />
            <Breadcrumb
                icon={<ScheduleIcon />}
                name="Schedule"
                path="/Schedule"
                isCurrentPage
            />
        </Breadcrumbs>
      <Grid container spacing={2}>
        {/* <Grid item xs={1}></Grid> */}
          {/* Place the DataGrid within its own Grid item */}
          {showData ? (<>
          {data.length > 0 ? (
            <Grid item xs={12} sx={{ m: 4, height: "77vh", width:"95vw" }}>
            <DataGrids rows={data} columns={columns} page_size={10}/>
            </Grid>
          ) : null}
          {!loanScheduleLoading && data.length <= 0 ? (
            <Grid item xs={10} sx={{ mt: 4 }}>
            <Alert severity="warning">
              The schedule is not yet generated for this loan.{" "}
            </Alert>
            </Grid>
          ) : null}
          </>):(<>
        <Grid item xs={12} md={12}>
              <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
            </Grid>
      </>)}
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default Schedule;
