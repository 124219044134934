import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import Hero from "./Banner/Banner";
import Programs from "./Features/Features";
import Title from "./Title/Title";
import About from "./About/About";
import Campus from "./Benefits/Benefits";
import Testimonials from "./Testimonials/Testimonials";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import VideoPlayer from "./VideoPlayer/VideoPlayer";

const Index = () => {
  const [playState, setPlayState] = useState(false);

  return (
    <div>
      <Navbar />
      <Hero />
      <div>
        <About setPlayState={setPlayState} />
        <Title subTitle="LENDSPHERE FEATURES" title="What We Offer" />
        <Programs />
        <Title subTitle="Benefits" title="Lendsphere Key Benefits" />
        <Campus />
        <Title subTitle="Clients" title="Our Clients" />
        <Testimonials />
        <Title subTitle="Request Demo" title="Get in Touch" />
        <Contact />
        <Footer />
      </div>
      <VideoPlayer playState={playState} setPlayState={setPlayState} />
    </div>
  );
};

export default Index;
