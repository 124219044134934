import React from "react";
import "./Contact.css";
import msg_icon from "../../../Assets/msg-icon.png";
import mail_icon from "../../../Assets/mail-icon.png";
import phone_icon from "../../../Assets/phone-icon.png";
import web from "../../../Assets/web.png";
import location_icon from "../../../Assets/location-icon.png";
import white_arrow from "../../../Assets/white-arrow.png";

const Contact = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    // // ------Enter your web3forms access key below-------

    // formData.append("access_key", "-----Enter your web3forms key----");

    // const res = await fetch("https://api.web3forms.com/submit", {
    //   method: "POST",
    //   body: formData,
    // }).then((res) => res.json());

    // if (res.success) {
    //   console.log("Success", res);
    //   setResult(res.message);
    //   event.target.reset();
    // } else {
    //   console.log("Error", res);
    //   setResult(res.message);
    // }
  };

  return (
    <div className="contact">
      <div className="contact-col">
        <h3>
          Send us a message <img src={msg_icon} alt="" />
        </h3>
        <p>
          Please let us know how we can help grow your business. We look forward
          to hearing from you!
        </p>
        <ul>
          <li>
            <img src={mail_icon} alt="" />
            hello@corestrat.ai
          </li>
          <li>
            <img src={web} alt="" />
            https://corestrat.ai/
          </li>
          {/* <li>
            <img src={phone_icon} alt="" />
            +1 123-456-7890
          </li> */}
          <li>
            <img src={location_icon} alt="" /># LGF, Tower ‘B’, Diamond
            District,
            <br /> Old HAL airport Road, <br />
            Domlur, Bangalore 560008
          </li>
        </ul>
      </div>
      <div className="contact-col">
        <form onSubmit={onSubmit}>
          <label>Your name</label>
          <input
            type="text"
            name="name"
            placeholder="Enter your name"
            required
          />
          <label>Phone Number</label>
          <input
            type="tel"
            name="phone"
            placeholder="Enter your mobile number"
            required
          />
          <label>Write your messages here</label>
          <textarea
            name="message"
            rows="6"
            placeholder="Enter your message"
            required
          ></textarea>
          <button type="submit" className="btn dark-btn">
            Submit now <img src={white_arrow} alt="" />
          </button>
        </form>
        <span>{result}</span>
      </div>
    </div>
  );
};

export default Contact;
