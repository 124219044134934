import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Button,
  Grid,
  Alert,
  MenuItem
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./signup.css"; // Assume the styles are defined in a separate CSS file
import {
  usersignup,
  ActivateUser,
  getPartners,
  DeclineUser,
} from "./../Common/Apis/api";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import { blueButtonStyle } from "../../constant2";
const SignUp = () => {
  var act_url = [" ", " "],
    act_token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const partnersArray = useSelector(
    (state) => state.DetailsReducers.partnersListResponse
  );
  const [isPageLoad, setisPageLoad] = React.useState(false);
  const [isAPILoading, setisAPILoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [failtext, setfailtext] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [partner_id, setPartner_ID] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [PasswordError, setPasswordError] = React.useState(false);
  const [issignedup, setissignedup] = React.useState(false);
  const [isactivate, setisactivate] = React.useState(false);
  const [isdeclined, setisdeclined] = React.useState(false);
  const [issignupfail, setissignupfail] = React.useState(false);
  const [isactivatefail, setisactivatefail] = React.useState(false);
  const [isdeclinefail, setisdeclinefail] = React.useState(false);
  const [emptyUsername, setEmptyUsername] = useState(false);
  const [emptyPartner, setEmptyPartner] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    username: "",
    password: "",
    partner_id: "",
  });
  useEffect(() => {
    dispatch(getPartners());
  }, []);
  const handleDropdownChange = (e) => {
    setEmptyPartner(false);
    setPartner_ID(e.target.value);
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleloginClick = () => {
    navigate("/login");
  };

  const handleInputUsername = (e) => {
    setEmptyUsername(false);
    setUsername(e.target.value);
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleInputPassword = (e) => {
    setEmptyPassword(false);
    setPassword(e.target.value);
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (regex.test(e.target.value)) {
      setPasswordError(false);
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    } else {
      setPasswordError(true);
    }
  };

  const handleInputEmail = (e) => {
    setEmptyEmail(false);
    setEmail(e.target.value);
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (regex.test(e.target.value)) {
      setEmailError(false);
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    } else {
      setEmailError(true);
    }
  };

  const signupclick = async (event) => {
    setissignupfail(false);
    event.preventDefault(); // Prevent the default form submission
    if (
      credentials.username != "" &&
      credentials.partner_id != "" &&
      credentials.email != "" &&
      credentials.password != ""
    ) {
      try {
        setisAPILoading(true);
        const response = await usersignup(credentials);
        setisAPILoading(false);
        if (response.status == 201) {
          setissignedup(true);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
        if (response.status == 400) {
          setfailtext(response.data);
          setissignupfail(true);
        }
      } catch (error) {
      }
    } else {
      if (credentials.username == "") {
        setEmptyUsername(true);
      } else {
        if (credentials.email == "") {
          setEmptyEmail(true);
        } else {
          if (credentials.password == "") {
            setEmptyPassword(true);
          } else {
            setEmptyPartner(true);
          }
        }
      }
    }
  };

  const act_User = async (e) => {
    try {
      setisAPILoading(true);
      const response = await ActivateUser(act_token);
      setisAPILoading(false);
      if (response.status == 200) {
        setisactivate(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
      if (response.status == 404) {
        setfailtext(response.data);
        setisactivatefail(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
    }
  };

  const decline_User = async (e) => {
    try {
      setisAPILoading(true);
      const response = await DeclineUser(act_token);
      setisAPILoading(false);
      if (response.status == 200) {
        setisdeclined(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
      if (response.status == 404) {
        setfailtext(response.data);
        setisdeclinefail(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
    }
  };

  if (!isPageLoad) {
    const url = window.location.href;
    const actcheck = "?act_token=";
    const declinecheck = "?decline_token=";
    if (url.includes(actcheck)) {
      act_url = url.split("?act_token=");
      act_token = act_url[1];
      act_User();
    }
    if (url.includes(declinecheck)) {
      act_url = url.split("?decline_token=");
      act_token = act_url[1];
      decline_User();
    }
    setisPageLoad(true);
  }

  return (
    <Grid container className="signup-container">
      {isAPILoading ? <CustomLoader /> : null}
      <Grid item xs={12} md={6} className="blue-background">
        <div className="co-lending-container">
          <Typography variant="h4" className="co-lending-text">
            Lendsphere
          </Typography>
          <Typography variant="body1" className="tagline-text">
            Your Trusted Financial Partner
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} md={6} className="signup-form">
        {/* <img src="your-logo.png" alt="Logo" className="logo" /> */}
        <form className="form-grid">
          <div>
            <Typography variant="h5" gutterBottom textAlign="center">
              Sign Up
            </Typography>
          </div>
          {isactivatefail ? (
            <Alert
              severity="error"
              style={{
                maxHeight: "5vh",
              }}
            >
              {failtext}
            </Alert>
          ) : null}
          {isdeclinefail ? (
            <Alert
              severity="error"
              style={{
                maxHeight: "5vh",
              }}
            >
              {failtext}
            </Alert>
          ) : null}
          {isactivate ? (
            <Alert
              severity="success"
              style={{
                maxHeight: "5vh",
              }}
            >
              User actiavted successfully
            </Alert>
          ) : null}
          {isdeclined ? (
            <Alert
              severity="success"
              style={{
                maxHeight: "5vh",
              }}
            >
              User declined successfully
            </Alert>
          ) : null}
          {issignedup ? (
            <Alert
              severity="success"
              style={{
                maxHeight: "5vh",
              }}
            >
              User signed up successfully
            </Alert>
          ) : null}
          {issignupfail ? <Alert severity="error">{failtext}</Alert> : null}
          {emptyUsername ? (
            <Alert severity="error">Please enter a Username.</Alert>
          ) : null}
          {emptyPartner ? (
            <Alert severity="error">Please select a Partner</Alert>
          ) : null}
          {emptyPassword ? (
            <Alert severity="error">Please enter your password</Alert>
          ) : null}
          {emptyEmail ? (
            <Alert severity="error">Please enter an Email</Alert>
          ) : null}
          <TextField
            label="Email"
            id="email"
            name="email"
            variant="outlined"
            className="input"
            fullWidth
            value={email}
            error={emailError}
            onChange={(e) => handleInputEmail(e)}
            helperText={emailError ? "Email is Invalid." : null}
          />
          <TextField
            label="Username"
            id="username"
            name="username"
            variant="outlined"
            className="input"
            fullWidth
            value={username}
            onChange={(e) => handleInputUsername(e)}
          />
          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            variant="outlined"
            className="input"
            fullWidth
            value={password}
            error={PasswordError}
            onChange={(e) => handleInputPassword(e)}
            helperText={
              PasswordError
                ? "Your password must be have at least 8 characters long 1 uppercase & 1 lowercase character 1 number"
                : null
            }
          />
          <TextField
            select
            value={partner_id}
            onChange={(e) => handleDropdownChange(e)}
            fullWidth
            name="partner_id"
            label="Select Partner"
          >
            {partnersArray?.map((option, key) => (
              <MenuItem
                key={key}
                value={option.id}
                sx={{
                  fontFamily: "'Roboto Condensed', sans-serif",
                  "&:hover": {
                    borderLeft: "5px solid var(--primary-color)",
                    borderRadius: 1,
                  },
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <div className="login">
            <Typography variant="body2">
              <a className="login-link" onClick={handleloginClick}>
                Existing User? Login.
              </a>
            </Typography>
          </div>

          <Button
            type="submit"
            className="signup-button"
            sx={blueButtonStyle}
            onClick={(event) => signupclick(event)}
          >
            Sign Up
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default SignUp;
