import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Grid, Typography, ThemeProvider} from "@mui/material";
import { theme } from "../../constant2";

const FullScreenDialog = ({ open, onClose, powerBiReport }) => {
  return (
    <ThemeProvider theme={theme}>
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogContent>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 30, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>
              Power BI Reports
            </Typography>
          </Grid>
        </Grid>
        <div
          dangerouslySetInnerHTML={{ __html: powerBiReport }}
          style={{ overflow: "auto", margin: "0% !important", height: "100vh" }}
        />
      </DialogContent>
    </Dialog>
    </ThemeProvider>
  );
};

export default FullScreenDialog;
