import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import { blue } from "@mui/material/colors";
import Header from "../Common/Headers/header";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import { uploadData, getLoanCategories } from "../Common/Apis/api";
import Alert from "@mui/material/Alert";
import { FormControl, InputLabel, Select, ThemeProvider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import logout from "../Common/Logout/logout";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import { theme } from "../../constant2";

const UploadDataComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editAccess, seteditAccess] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isfileUploaded, setisfileUploaded] = useState(false);
  const [isUploadReady, setisuploadready] = useState(false);
  const [isDisabled, setisDisabled] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [isfileUploadedSuccessful, setisfileUploadedSuccessful] =
    useState(false);
  const loanCategories = useSelector(
    (state) => state.DetailsReducers.loanCategoryResponse
  );
  const loanCategoriesLoading = useSelector(
    (state) => state.DetailsReducers.loanReportLoading
  );
  const handleProgramChange = (event) => {
    setSelectedProgram(event.target.value);
  };
  const handleFileTypeChange = (event) => {
    setSelectedFileType(event.target.value);
  };
  useEffect(() => {
    if (selectedProgram != "" && selectedFileType != "") {
      setisuploadready(true);
    }
  }, [selectedProgram, selectedFileType]);
  useEffect(() => {
    dispatch(getLoanCategories(handleLogoutButton));
  }, []);
  const handleFileUpload = (files) => {
    // Assuming you want to handle only one file, you can access it using files[0]
    setUploadedFile(files[0]);
    if (isUploadReady == true) {
      setisDisabled(false);
    }
  };

  const handleUploadData = async () => {
    setisfileUploaded(false);
    setisfileUploadedSuccessful(false);
    if (uploadedFile == null) {
      setisfileUploaded(true);
      return;
    }
    // Add your logic for handling the file upload here
    let data = null;
    data = new FormData();
    data.append("loan_applications", uploadedFile);
    try {
      const results = await uploadData(data, handleLogoutButton);
      if (results) {
        setisfileUploadedSuccessful(true);
      }
    } catch (error) {}
    // Add logic for uploading data to the server
  };

  useEffect(() => {
    const accessLevel = levelsofAccess("LoanOnboarding");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage( "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
      <Header />
      {loanCategoriesLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      {/* Breadcrumbs */}
      <Breadcrumbs style={{ position: "relative" }}>
        <Breadcrumb
          icon={<AssignmentIndIcon />}
          name="Dashboard"
          path="/Dashboard"
        />
        <Breadcrumb
          icon={<CardTravelIcon />}
          name="Loan Onboarding"
          path="/LoanOnboarding"
          isCurrentPage
        />
      </Breadcrumbs>

      <div
        style={{
          width: "60%",
          margin: "auto",
          marginTop: "50px",
          textAlign: "center",
        }}
      >
        {isfileUploaded ? (
          <Alert sx={{ m: 2 }} severity="error">
            Please choose a file to upload.
          </Alert>
        ) : null}
        {isfileUploadedSuccessful ? (
          <Alert sx={{ m: 2 }} severity="success">
            File Uploaded Successfully! 
          </Alert>
        ) : null}
        {showData ?(<>
        {loanCategories?.main?.length == 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Alert severity="info">
                Upload Data functionality is available only for Loan
                Originators.
              </Alert>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} md={6}>
                {loanCategories ? (
                  <FormControl fullWidth>
                    <InputLabel id="loan-category-label">
                      Loan Category
                    </InputLabel>
                    <Select
                      labelId="loan-category-label"
                      id="loan-category-select"
                      value={selectedProgram}
                      onChange={handleProgramChange}
                      label="Loan Category"
                    >
                      {loanCategories?.main?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.loan_category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null}
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  select
                  label="File Type"
                  value={selectedFileType}
                  onChange={handleFileTypeChange}
                >
                  <MenuItem value=".xls">.csv</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <div
              style={
                !isUploadReady
                  ? { pointerEvents: "none", opacity: 0.5, margin: "20px" }
                  : { margin: "20px" }
              }
            >
              <DropzoneArea
                acceptedFiles={[".csv"]}
                filesLimit={1}
                maxFileSize={5000000} // 5 MB limit
                dropzoneText={<span>Drag and drop a CSV file here or click</span>}
                onChange={handleFileUpload}
                disabled={!isUploadReady}
                showAlerts={false}
                showFileNames={true}
                dropzoneClass={`
      border: 2px dashed ${blue[500]};
      background-color: #f8f8f8;
      border-radius: 8px;
    `}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUploadData}
              disabled={!uploadedFile}
              style={
                uploadedFile
                  ? {
                      color: "#fff",
                      backgroundColor: "#1976d2",
                      //boxShadow:"0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                      // Add other styles as needed
                      fontWeight: "500",
                      fontSize: "0.875rem",
                      lineHeight: "1.75",
                      letterSpacing: "0.02857em",
                      textTransform: "uppercase",
                      minWidth: "64px",
                      padding: "6px 16px",
                      borderRadius: "4px",
                    }
                  : null
              }
            >
              Upload Data
            </Button>
          </>
        )}
        </>):(<>
          <Grid item xs={12} md={12}>
              <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
            </Grid>
        </>)}
      </div>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};

export default UploadDataComponent;
